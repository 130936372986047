export const empty = (data) => {
    if (!data) {
        return true
    }
    if (data === '0') {
        return true
    }
    if (typeof (data) === 'number' || typeof (data) === 'boolean') {
        return false;
    }
    if (typeof (data) === 'undefined' || data === null) {
        return true;
    }
    if (data == 'undefined' || data == null) {
        return true;
    }
    if (typeof (data.length) !== 'undefined') {
        return data.length === 0;
    }
    var count = 0;
    for (var i in data) {
        if (data.hasOwnProperty(i)) {
            count++;
        }
    }
    return count === 0;
}

// export const parseDescription = (description) => {
//     const sentences = description.split(/[.]/); 
//     const trimmedSentences = sentences.filter(sentence => sentence.trim() !== '');
  
//     const parsedSentences = trimmedSentences.map(sentence => {
//       const tempDiv = document.createElement('div');
//       tempDiv.innerHTML = sentence;
      
//       if (tempDiv.querySelector('ul')) {
//         const listItems = Array.from(tempDiv.querySelectorAll('li')).map(li => li.textContent);
//         return [tempDiv.textContent || tempDiv.innerText || '', ...listItems];
//       } else {
//         return [tempDiv.textContent || tempDiv.innerText || ''];
//       }
//     });
    
//     return parsedSentences.flat();
//   }




// export const formatDescription = (description) => {
//     description = description?.replace(/<p><br><\/p>/g, '<br>');
//     description = description?.replace(/<p>/g, '<p>• ');
//     description = description?.replace(/<ul><li>/g, '');
//     description = description?.replace(/<li>/g, '<p>• ');
//     description = description?.replace(/<\/li>/g, '</p>');
//     description = description?.replace(/<\/ul>/g, '');

//     if (description && !description.startsWith('<p>• ')) {
//         description = '<p>• ' + description;
//     }

//     return description;
// };

export const  parseDescription=(description)=>{
  const sentences = description.split(/[.]/);  
  const trimmedSentences = sentences.filter(sentence => sentence.trim() !== '');

  const parsedSentences = trimmedSentences.map(sentence => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = sentence;
    
    if (tempDiv.querySelector('ul')) {
      const listItems = Array.from(tempDiv.querySelectorAll('li')).map(li => li.textContent);
      return [tempDiv.textContent || tempDiv.innerText || '', ...listItems];
    } else {
      return [tempDiv.textContent || tempDiv.innerText || ''];
    }
  });
  
  return parsedSentences.flat();
}

export const formatDescription = (description) => {
    if (!description) return '';
  
    // Handle ordered lists
    let listIndex = 1;
    if (description.includes('<ol>')) {
      description = description.replace(/<ol>/g, '');
      description = description.replace(/<\/ol>/g, '');
      // description = description.replace(/<li>/g, () => {
      //   const item = `${listIndex++}. `;
      //   return `<p>${item}$1</p>`;
      // });
      description = description.replace(/<li>/g, () => {
        const item = `${listIndex++}. `;
        return `<p>&nbsp;&nbsp;&nbsp;${item}`;
      });
    }
  
    // Handle unordered lists
    if (description.includes('<ul>')) {
      description = description.replace(/<ul>/g, '');
      description = description.replace(/<\/ul>/g, '');
      // description = description.replace(/<li>/g, '<p>&nbsp;&nbsp;&nbsp;• $1</p>');
      description = description.replace(/<li>/g, '<p>&nbsp;&nbsp;&nbsp;• ');
    }
    description = description.replace(/<\/li>/g, '');
    // Clean up any extra <p> tags that might be left
    description = description.replace(/<p><\/p>/g, '');
  
    return description;
  }
  // export const formatDescription = (description) => {
  //   if (!description) return '';
  
  //   // Handle ordered lists
  //   let listIndex = 1;
  //   if (description.includes('<ol>')) {
  //     description = description.replace(/<ol>/g, '');
  //     description = description.replace(/<\/ol>/g, '');
  //     description = description.replace(/<li>/g, () => {
  //       const item = `${listIndex++}. `;
  //       return `<p>&nbsp;&nbsp;&nbsp;${item}`;
  //     });
  //   }
  
  //   // Handle unordered lists
  //   if (description.includes('<ul>')) {
  //     description = description.replace(/<ul>/g, '');
  //     description = description.replace(/<\/ul>/g, '');
  //     description = description.replace(/<li>/g, '<p>&nbsp;&nbsp;&nbsp;• ');
  //   }
  //   description = description.replace(/<\/li>/g, '');
  //   description = description.replace(/<p><\/p>/g, '');
  
  //   return description;
  // };