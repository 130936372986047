import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
// import Map, { Marker } from 'react-map-gl';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import TinySlider from 'tiny-slider-react';
import tick from '../../assets/images/tick1.png';
import star from '../../assets/images/rating-star.png';
import { IoMdClose } from 'react-icons/io';
import { MAP_API_KEY, REST_API_END_POINT } from '../../constants/DefaultValue';
import { empty, formatDescription} from '../../common/Helper';
import 'mapbox-gl/dist/mapbox-gl.css';
import { BsExclamationOctagon } from 'react-icons/bs';
import { LiaFacebookF } from 'react-icons/lia';
import { AiOutlineGoogle, AiOutlineInstagram, AiOutlineTwitter } from 'react-icons/ai';
import { BiWallet } from 'react-icons/bi';
import { dataArray } from '../../data/data';
import NavbarNew from '../../components/navbar-new';
import "leaflet/dist/leaflet.css";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import { compose, withProps } from 'recompose';
import NotificationNav from '../../components/notificationNav';




const settings = {
  items: 1,
  controls: true,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: false,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  nav: false,
  speed: 400,
  gutter: 0,
  controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>']
};

const getData = (source, ids) => {
  if (!ids) return [];
  return ids.split(',').map(id => source.find(item => item.id == id)).filter(item => item !== undefined);
};

export default function ConsultantsDetail() {
  const { consultId, tab } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);
  const [placeBid, setPlaceBid] = useState(false);
  const [details, setDetails] = useState({});
  const [notification, setNotification] = useState({});
  const [data, setData] = useState({});
  const [otherServices, setOtherServices] = useState([]);
  const [visaServices, setVisaServices] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [locServices, setLocServices] = useState([]);
  const [offer, setOffers] = useState([]);
  const [successImgs, setSuccessImgs] = useState([]);
  const [servicePoints, setServicesPoints] = useState([]);
  const [selectedCity, setSelectedCity] = useState({});
  const [faqs, setFaq] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [review, setReview] = useState(0);
  const [locationData, setLocationData] = useState(true);
  const mapRef = useRef();
  {console.log("Sevice Points11111111111111",servicePoints);}
  const fetchConsultantdetailsById = () => {
    axios.post(`${REST_API_END_POINT}consultant/get-consultant-details-by-id`, { consultId })
      .then((res) => {
        if (res.data.status === 1) {
          setData(res.data.consultData);
          setDetails(res.data.details);
          setFaq(res.data.faq);
          setReview(res.data.review);
          setNotification(res.data.details.notification_btn);
          setSuccessImgs(res.data.details?.success_images.split(','));
          if (res.data.isEdit) {
            setStaffs(getData(res.data.staffs.data, res.data.details?.staff_ids));
            setLocServices(getData(res.data.services.data, res.data.details?.location_ids));
            setSelectedCity(getData(res.data.services.data, res.data.details?.location_ids)[0]);
            setOffers(getData(res.data.offers.data, res.data.details?.offer_ids));
          }
          // setOtherServices(res.data.otherServices);
          // setVisaServices(res.data.visaServices);

          const updatedService =res.data.visaServices
          ?.slice() 
        .sort((a, b) => a.id - b.id) 
        .map(item => {
          return {
          ...item,
          visaServiceName: item.name,
          visaServiceFee: item.fee
        }
      })
      setVisaServices(updatedService);
      const initialOtherService = res.data.otherServices      
        ?.slice() 
        .sort((a, b) => a.id - b.id) 
        .map(item => {
          return {
          ...item,
          otherServiceName: item.name,
          otherServiceFee: item.fee
      }
        }
      )
      setOtherServices(initialOtherService)
    }})
      
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem('userVisa')));
    fetchConsultantdetailsById();
  }, [consultId]);

  const handleCityClick = (city) => {
    console.log("cityyy", city);
    setSelectedCity(city);
    const parsedServicePoints = parseDescription(city?.description);
    console.log("Sevice Points11111111111111222",parsedServicePoints)
    setServicesPoints(parsedServicePoints);
    {console.log("Sevice Points11111111111111",servicePoints)}
    if(city.latitude==null||city.longitude==null){
      setLocationData(false)
    }else{
      setLocationData(true)
    }
  };


  // useEffect(() => {
  //   const parsedServicePoints = parseDescription(selectedCity?.description);
  //   setServicesPoints(parsedServicePoints);
  //   if (mapRef.current && isValidCoordinate(parseFloat(selectedCity?.longitude)) && isValidCoordinate(parseFloat(selectedCity?.latitude))) {
  //     mapRef.current.flyTo({
  //       center: [parseFloat(selectedCity?.longitude), parseFloat(selectedCity?.latitude)],
  //       essential: true
  //     });
  //   }
  // }, [selectedCity]);
  useEffect(()=>{
    const parsedServicePoints = parseDescription(selectedCity?.description);
    setServicesPoints(parsedServicePoints);
   },[selectedCity])


  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  };

  const initialFAQ = getData(faqs, details?.faq_ids);
  // const formatDescription = (description) => {
  //   if (!description) return '';
  
  //   // Process ordered list items
  //   description = description.replace(/<ol>/g, '').replace(/<\/ol>/g, '');
  //   description = description.replace(/<li>(.*?)<\/li>/g, (match, itemContent) => {
  //     return `<p>&nbsp;&nbsp;&nbsp;${itemContent}</p>`; // indent list items for clarity
  //   });
  
  //   // Process unordered list items, add bullet points
  //   description = description.replace(/<ul>/g, '').replace(/<\/ul>/g, '');
  //   description = description.replace(/<li>(.*?)<\/li>/g, (match, itemContent) => {
  //     return `<p>&nbsp;&nbsp;&nbsp;• ${itemContent}</p>`; // add bullet points
  //   });
  
  //   // Clean up any remaining tags and ensure no empty paragraphs
  //   description = description.replace(/<p><\/p>/g, '');
  //   console.log("Transformed Description:", description);
  //   return description;
  // };
  

 
  const parseDescription = (description = '') => {
    if (!description) {
      return [];
    }
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = description;
    const paragraphs = Array.from(tempDiv.querySelectorAll("p"));
    const parsedSentences = paragraphs.map((paragraph) => {
      const textContent = paragraph.textContent.trim();
      return textContent;
    });
    return parsedSentences.filter((text) => text.trim() !== "");
  };

  const expireDate = moment.unix(details?.expiredate);
  const formattedExpireDate = expireDate.format('MMMM D, YYYY');
  // const isValidCoordinate = (coord) => typeof coord === 'number' && !isNaN(coord);

  const MapWithMarkers = compose(
    withProps({
      googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `410px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )((props) =>
          <GoogleMap
           defaultZoom={8} 
          defaultCenter={{ lat: parseFloat(selectedCity?.latitude), lng: parseFloat(selectedCity?.longitude) }}>
            <Marker            
              position={{ lat: parseFloat(selectedCity?.latitude), lng: parseFloat(selectedCity?.longitude) }}
               title={selectedCity?.place_name}
            />
          </GoogleMap>
  );
  return (
    <>
    {notification===1&&<NotificationNav notificationData={details?.notifications}/>}
      <NavbarNew noHomeTab={true} tab={tab} notification={notification===1?true:false} />
      <section className="relative md:py-24 py-16" style={{ marginTop:notification===1?"70px": "20px" }}>
        <div className="container">
          {/* {notification === 1 && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="flex items-center p-3 mt-3 rounded-lg" style={{ width: "100%", backgroundColor: "grey", marginBottom: "10px", justifyContent: "space-between", color: "white" }}>
                <div className="ms-2 items-center" style={{ display: "flex", justifyContent: "flex-start", flexDirection: "row", textAlign: "left" }}>
                  <BsExclamationOctagon style={{ color: "white", marginRight: "10px" }} className="text-3xl" />
                  <div>
                    <span className="block">{details?.notifications}</span>
                  </div>
                </div>
                <div className="ms-2" style={{ display: "flex", justifyContent: "flex-end", flexDirection: "column", textAlign: "right" }}>
                </div>
              </div>
            </div>
          )} */}
          <div className="md:flex">
            <div className="lg:w-3/5 md:w-full md:mb-8 md:order-2" style={{ paddingInline: "1rem" }}>
              <div style={{ position: "relative", width: "100%", maxWidth: "100%", overflow: "hidden" }}>
                <img
                  style={{ width: "100%", left: "0", minWidth: "300px", height: "315px", borderRadius: "10px", objectFit: "cover" }}
                  src={data?.cover_image}
                  alt="Visa Search Image"
                />
                <div className="visa-image" style={{ display: "flex", justifyContent: "space-between" }}>
                  <span>{data?.name}</span>
                  <ul className="list-none md:text-start" style={{ paddingRight: "17px" }}>
                    <li className="inline space-x-1">
                      <Link onClick={() => window.open(details?.facebook, "_blank")} className="btn btn-icon btn-sm border border-black rounded-md hover:border-black dark:hover:border-black hover:bg-black dark:hover:bg-black">
                        <LiaFacebookF style={{ color: "white" }} className="text-base" />
                      </Link>
                    </li>
                    <li className="inline space-x-1">
                      <Link onClick={() => window.open(details?.instagram, "_blank")} className="btn btn-icon btn-sm border border-black rounded-md hover:border-black dark:hover:border-black hover:bg-black dark:hover:bg-black">
                        <AiOutlineInstagram style={{ color: "white" }} className="text-base" />
                      </Link>
                    </li>
                    <li className="inline space-x-1">
                      <Link onClick={() => window.open(details?.twitter, "_blank")} className="btn btn-icon btn-sm border border-black rounded-md hover:border-black dark:hover:border-black hover:bg-black dark:hover:bg-black">
                        <AiOutlineTwitter style={{ color: "white" }} className="text-base" />
                      </Link>
                    </li>
                    <li className="inline space-x-1">
                      <Link onClick={() => window.open(details?.google, "_blank")} className="btn btn-icon btn-sm border border-black rounded-md hover:border-black dark:hover:border-black hover:bg-black dark:hover:bg-black">
                        <AiOutlineGoogle style={{ color: "white" }} className="text-base" />
                      </Link>
                    </li>
                  </ul>
                  <div className="bagde-flag-wrap2">
                    <img style={{ position: "absolute", top: "0", right: "0", width: "100%", height: "100%", objectFit: "cover", zIndex: "2" }} src={data?.logo} alt="College Logo" />
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-2/5 md:w-full md:pe-4 md:order-1 md:mt-0 mt-10 md:mt-0 lg:mt-0 mb-8">
              <div>
                <div className="sticky top-20"></div>
                <div className="offer-badge1" style={{ marginTop: "-70px" }}>
                  <div className="badge-text-container" style={{ height: 80, width: 80, paddingTop: "18px", textAlign: "center", alignContent: "center" }}>
                    <span className="badge-text1" style={{ textAlign: "center", whiteSpace: "nowrap", fontSize: "22px", marginLeft: "-30px" }}>
                      Fees & Charges
                    </span>
                    <br />
                  </div>
                </div>
                <div className="product-card">
                  <div className="color-price" style={{ marginTop: "4.5rem" }}>
                    <div className="color-option">
                      <span style={{ marginRight: "15px", fontSize: "25px" }}>
                        <BiWallet style={{ color: "black" }} />
                      </span>
                      <span class="color">Consultant Charge</span>
                    </div>
                    <div className="price">
                      <div className="color-option">
                        <span className="color">
                          <span style={{ fontWeight: "bold" }}>${details?.fee}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  {visaServices?.map((row, index) => (
                    <div key={index} className="color-price">
                      <div className="color-option">
                        <span style={{ marginRight: "15px", fontSize: "25px" }}>
                          <BiWallet style={{ color: "black" }} />
                        </span>
                        <span class="color">{row.name}</span>
                      </div>
                      <div className="price">
                        <div className="color-option">
                          <span className="color">
                            <span style={{ fontWeight: "bold" }}>${row?.fee}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="color-price">
                    <div className="color-option" style={{ zIndex: 9999 }}>
                      <div className="popover-container1" style={{ display: "flex", alignItems: "center", zIndex: 9999 }}>
                        <span style={{ marginRight: "13px", fontSize: "25px" }}>
                          <span className="ph--info" style={{ color: "black", fontSize: "30px", zIndex: 9999 }} />
                        </span>
                        <span className="color" style={{ textDecoration: "underline", zIndex: 9999 }}>
                          Other Visas
                        </span>
                        <div className="popover-content11" style={{ marginLeft: "30px", marginTop: "-150px", zIndex: 9999 }}>
                          <div className="popover1" style={{ zIndex: 9999 }}>
                            <h3 className="popover-heading1 color-price">Other Visas:</h3>
                            {otherServices?.map((row) => (
                              <div key={row.id} style={{ display: "flex", justifyContent: "space-between" }}>
                                <p className="popover-paragraph1 color-price">{row.name} :</p>
                                <p className="popover-paragraph1 color-price" style={{ fontWeight: "bold", color: "#000" }}>
                                  $ {row.fee}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lg:flex" style={{ marginLeft: "0px", marginTop: "0px" }}>
          <div className="p-6 w-full" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginBottom: "20px" }}>
            <div className="flex flex-wrap justify-between wrapp" style={{ marginBottom: "20px", gap: "20px" }}>
              <div style={{ marginBottom: "20px", marginRight: "200px" }}>
                <div className="buttonn" style={{ textAlign: "center", marginTop: "0px" }}>
                  <div className="buttonn-layer1"></div>
                  {!empty(userData) ? (
                    <div
                      onClick={() =>
                        navigate("/apply-visa-consultant", {
                          state: {
                            consultId: consultId,
                          },
                        })
                      }
                      style={{
                        color: "#000000",
                        fontWeight: "bold",
                        fontSize: "18px",
                        cursor: "pointer",
                        marginLeft: "15px",
                        borderBottom: "2px solid #000",
                        paddingBottom: "3px",
                        whiteSpace: "nowrap",
                      }}
                      className="under"
                      id="button"
                    >
                      <span class="icons8--visa"></span>
                      Apply Now For Visa
                    </div>
                  ) : (
                    <Link
                      to={"/signup-visa/" + tab}
                      style={{
                        color: "#000000",
                        fontWeight: "bold",
                        fontSize: "18px",
                        cursor: "pointer",
                        marginLeft: "15px",
                        borderBottom: "2px solid #000",
                        paddingBottom: "3px",
                        whiteSpace: "nowrap",
                      }}
                      className="under"
                      id="button"
                    >
                      Apply Now For Visa
                    </Link>
                  )}
                </div>
              </div>
              <div style={{ marginBottom: "20px", marginRight: "200px" }}>
                <div className="buttonn" style={{ textAlign: "center", marginTop: "0px" }}>
                  <div className="buttonn-layer1"></div>
                  {!empty(userData) ? (
                    <div
                      onClick={() =>
                        navigate("/apply-pr-visa-consultant", {
                          state: {
                            consultId: consultId,
                          },
                        })
                      }
                      style={{
                        color: "#000000",
                        fontWeight: "bold",
                        fontSize: "18px",
                        cursor: "pointer",
                        marginLeft: "15px",
                        borderBottom: "2px solid #000",
                        paddingBottom: "3px",
                        whiteSpace: "nowrap",
                      }}
                      className="under"
                      id="button"
                    >
                      <span class="mdi--pram"></span>
                      Apply Now For PR Visa
                    </div>
                  ) : (
                    <Link
                      to={"/signup-visa/" + tab}
                      style={{
                        color: "#000000",
                        fontWeight: "bold",
                        fontSize: "18px",
                        cursor: "pointer",
                        marginLeft: "15px",
                        borderBottom: "2px solid #000",
                        paddingBottom: "3px",
                        whiteSpace: "nowrap",
                      }}
                      className="under"
                      id="button"
                    >
                      Apply Now For PR Visa
                    </Link>
                  )}
                </div>
              </div>
              <div style={{ textAlign: "center", marginTop: "0px" }}>
                <div className="popover-container1">
                  <Link
                    className="color"
                    style={{
                      marginLeft: "10px",
                      color: "#000000",
                      fontWeight: "bold",
                      fontSize: "20px",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                  >
                    Rating : {review}
                  </Link>
                </div>
                <img src={star} height={32} width={32} alt="Image Description" className="badge-image" style={{ marginLeft: "0px", marginTop: "3px" }} />
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "-70px" }}>
          <div className="group rounded-xl bg-white dark:bg-slate-900 w-full mx-auto lg:max-w-2xl " style={{ zIndex: 1, marginTop: "40px", display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center" }}>
            <Link
              className="font-semibold transition-all duration-500 ease-in-out text-white bg-violet-6001  "
              type="button"
              style={{
                color: "black",
                width: "550px",
                height: "70px",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                padding: "22px",
                borderRadius: "14px",
              }}
            >
              Want to chat ?
            </Link>
          </div>
          <hr style={{ marginTop: "-36px", borderColor: "black", borderWidth: "1px" }} />
        </div>

       <div className="container anotherml" style={{ marginTop: "55px",}}>
        <div className="md:flex">
  <div className="w-full md:mb-8 md:order-2">
    <div className="lg:col-span-7 lg:ms-8">
      {staffs?.length > 0 && (
                  <div className="p-3 bg-gray-50 rounded-lg shadow1 mt-6 w-full">
                    {staffs
                      ?.filter((row) => row.status === 1)
                      ?.reduce((resultArray, item, index) => {
                        const chunkIndex = Math.floor(index / 5);

                        if (!resultArray[chunkIndex]) {
                          resultArray[chunkIndex] = []; // start a new chunk
                        }

                        resultArray[chunkIndex].push(item);

                        return resultArray;
                      }, [])
                      .map((staffChunk, chunkIndex) => (
                        <div key={chunkIndex} className="md:flex w-full mb-4">
                          {staffChunk.map((row, index) => (
                            <div key={index} className="md:w-1/5 p-2">
                              <div className="flex items-center">
                                <div className="relative inline-block">
                                  <img
                                    src={row?.profile_pic}
                                    style={{ width: "50px", height: "50px",objectFit:'cover' }}
                                    className="rounded-md"
                                    alt={row?.name}
                                  />
                                  {row.verified === 1 && (
                                    <i className="mdi mdi-check-decagram text-emerald-600 text-lg absolute -top-2 -end-2" />
                                  )}
                                </div>
                                <div className="ms-3">
                                  <Link className="font-semibold block text-black hover:text-black">
                                    {row?.name}
                                  </Link>
                                  <span className="text-black text-[16px] block mt-1">
                                    {row?.position}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="container" style={{ marginTop: "60px" }}>
          <div className="md:flex">
          <div className="lg:w-2.5/5 md:w-full md:pe-4 md:order-1 md:mt-0 mt-10 md:mt-0 lg:mt-0 mb-8">
              {!empty(selectedCity) && (
                <div>
                  <div className="sticky top-20"></div>
                  <div
                    className="group rounded-xl bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 overflow-hidden ease-in-out duration-500 w-full mx-auto lg:max-w-2xl"
                    style={{ marginTop: "5px" }}
                  >
                    <div className="lg:flex" style={{ display: "flex", flexDirection: "column" }}>
                      <div className="relative md:shrink-0">
                        <img
                          className="h-288 w-full object-cover"
                          src={selectedCity.cover_image}
                          alt={selectedCity.place_name}
                        />
                      </div>
                      <div className="p-6 w-full">
                        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
                          {locServices.slice(0, 6).map((city, index) => (
                            <>
                              <Link
                                key={city.id}
                                className={`color ${
                                  selectedCity.id === city.id
                                    ? "active-city px-3 py-2"
                                    : "px-3 py-2"
                                }`}
                                style={{
                                  color: "#000000",
                                  fontWeight: "bold",
                                  fontSize: "20px",
                                  cursor: "pointer",
                                  textAlign: "center",
                                }}
                                onClick={() => handleCityClick(city)}
                              >
                                {city.place_name}
                              </Link>
                            </>
                          ))}
                        </div>
                        <div
                          className="flex flex-col items-start mt-6 scroll-container"
                          style={{
                            height: "128px",
                            border: "1px solid #e6e6e6",
                            padding: "10px",
                            borderRadius: "7px",
                            cursor: "all-scroll",
                            overflowY: "scroll", // Ensure scrollbar appears only on the y-axis
                            msOverflowStyle: "none", // Internet Explorer 10+
                            scrollbarWidth: "thin", // Firefox
                          }}
                        >
                          <style>
                            {`
                              .scroll-container::-webkit-scrollbar {
                                width: 0.4em;
                              }

                              .scroll-container::-webkit-scrollbar-track {
                                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00);
                              }

                              .scroll-container::-webkit-scrollbar-thumb {
                                background-color: #000;
                                border-radius: 4px;
                              }
                            `}
                          </style>
                         
                          
                          {servicePoints?.map((content, index) => (
                            <div key={index} className="flex items-center mb-2">
                              <img
                                src={tick}
                                height={19}
                                width={19}
                                alt="Image Description"
                                className="badge-image"
                                style={{ marginLeft: "7px" }}
                              />
                              <Link
                                className="color"
                                style={{
                                  color: "#000000",
                                  fontSize: "19px",
                                  cursor: "pointer",
                                  textAlign: "left",
                                }}
                                
                              >
                                {content}
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="lg:w-2.5/5 md:w-full md:mb-8 md:order-2" style={{ paddingInline: "1rem" }}>
            {locationData&&
              <div style={{ position: "relative", width: "100%", maxWidth: "100%", overflow: "hidden", borderRadius: "20px" }}>
                <div className="mapp">
                  {/* <Map
                    ref={mapRef}
                    initialViewState={{
                      longitude: isValidCoordinate(parseFloat(selectedCity?.longitude)) ? parseFloat(selectedCity?.longitude) : 0,
                      latitude: isValidCoordinate(parseFloat(selectedCity?.latitude)) ? parseFloat(selectedCity?.latitude) : 0,
                      zoom:10,
                    }}
                    style={{ width: '100%', height: '490px' }}
                    mapStyle="mapbox://styles/mapbox/streets-v11"
                    mapboxAccessToken={MAP_API_KEY}
                  >
                    {isValidCoordinate(parseFloat(selectedCity?.longitude)) && isValidCoordinate(parseFloat(selectedCity?.latitude)) && (
                      <Marker
                        longitude={parseFloat(selectedCity?.longitude)}
                        latitude={parseFloat(selectedCity?.latitude)}
                        title={selectedCity?.place_name}
                      />
                    )}
                  </Map> */}

<MapWithMarkers />
                </div>
              </div>}
            </div>
          </div>
        </div>

       {details?.Offer_on_off===1&& <div className="container" style={{ marginTop: "50px" }}>
          <div className="md:flex">
            <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2">
              <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px]">
                <div className="lg:col-span-5">
                  <div className="md:col-span-5 relative">
                    <div className="tiny-one-icon-item">
                      <TinySlider settings={settings}>
                        {offer?.map((ele, index) => (
                          <div className="tiny-slide" key={index}>
                            <div className="m-2 p-3 bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800">
                              <div className="group relative overflow-hidden bg-white dark:bg-slate-900 rounded-md transition-all duration-500">
                                <img
                                  style={{
                                    objectFit: "cover",
                                    height: "259px",
                                    width: "722px",
                                  }}
                                  src={ele.offer_image}
                                  alt=""
                                />
                                <div className="relative p-6">
                                  <div className="text-center" style={{ textAlign: "center" }}>
                                    <Link className="title text-lg font-medium duration-500 ease-in-out" style={{ color: "#000" }}>
                                      {ele.title}
                                    </Link>
                                    <div className="flex justify-center mt-4">
                                      <Link
                                        onClick={() => window.open(ele?.link, "_blank")}
                                        className="px-6 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out text-white bg-violet-6001"
                                        type="button"
                                      >
                                        Click Here To Claim
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </TinySlider>
                    </div>

                    <div className="overflow-hidden after:content-[''] after:absolute after:h-14 after:w-14 after:bg-violet-600/10 dark:after:bg-violet-600/30 after:-top-[50px] after:start-[30%] after:-z-1 after:rounded-lg after:animate-[spin_10s_linear_infinite]"></div>
                  </div>
                </div>

                <div className="lg:col-span-7 lg:ms-8">
                  <h5 className="md:text-2xl text-xl font-semibold" style={{ color: "#000" }}>
                    {details?.offer_title} with {data?.name} !
                  </h5>

                  <span className="font-medium text-slate-400 block mt-2">
                    {details?.offer_sub_title}:
                  </span>

                  <p className="text-slate-400 mt-4 scroll-right">
                    {details?.offer_description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>}

        <div className="container" style={{ marginTop: "100px" }}>
          <div className="md:flex">
            <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2">
              <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px]">
                <div className="lg:col-span-7 lg:me-8">
                  <h5 className="md:text-2xl text-xl font-semibold" style={{ color: "#000" }}>
                    {details?.success_title} From {data?.name}
                  </h5>
                  <span className="font-medium text-slate-400 block mt-2">
                    {details?.success_sub_title}:
                  </span>
                  <p className="text-slate-400 mt-4 scroll-right">
                    {details?.success_description}
                  </p>
                </div>

                <div className="lg:col-span-5">
                  <div className="md:col-span-5 relative">
                    <div className="tiny-one-icon-item">
                      <TinySlider settings={settings}>
                        {successImgs?.map((ele, index) => (
                          <div className="tiny-slide" key={index}>
                            <div className="m-2 p-3 bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800">
                              <div className="group relative overflow-hidden bg-white dark:bg-slate-900 rounded-md transition-all duration-500">
                                <img
                                  style={{
                                    objectFit: "cover",
                                    height: "359px",
                                    width: "722px",
                                  }}
                                  src={ele}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </TinySlider>
                    </div>

                    <div className="overflow-hidden after:content-[''] after:absolute after:h-14 after:w-14 after:bg-violet-600/10 dark:after:bg-violet-600/30 after:-top-[50px] after:start-[30%] after:-z-1 after:rounded-lg after:animate-[spin_10s_linear_infinite]"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!empty(initialFAQ) ? (
            <div className="md:flex" style={{ marginTop: "60px" }}>
              <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2">
                <li className="block">
                  <span
                    style={{
                      color: "#000000",
                      fontWeight: "bold",
                      fontSize: "30px",
                      cursor: "pointer",
                      textAlign: "left",
                    }}
                  >
                    FAQs
                  </span>
                </li>

                <div id="accordion-collapseone" data-accordion="collapse" className="mt-12">
                  {initialFAQ.map((item, index) => (
                    <div key={index} className="relative shadow1 dark:shadow1 rounded-md overflow-hidden mt-4">
                      <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                        <button
                          onClick={() => toggleAccordion(index)}
                          type="button"
                          style={{ color: "black" }}
                          className={`flex justify-between items-center p-5 w-full font-semibold text-start ${
                            activeIndex === index ? "bg-gray-50 dark:bg-slate-8002 text-violet-6001" : ""
                          }`}
                          data-accordion-target="#accordion-collapse-body-1"
                          aria-expanded="true"
                          aria-controls="accordion-collapse-body-1"
                        >
                          <span>{item.title}</span>
                          <svg
                            data-accordion-icon
                            className={`w-4 h-4  shrink-0 ${activeIndex === index ? "rotate-180" : "rotate-360"}`}
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </h2>
                      {activeIndex === index && (
                        <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                          <div className="p-5" style={{ backgroundColor: "rgb(110 110 110 / 15%)" }}>
                            <p
                              style={{ color: "black" }}
                              dangerouslySetInnerHTML={{
                                __html: formatDescription(item?.description),
                              }}
                              >
                            </p>
                            <br/>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 50,
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
          className={`m-auto justify-center items-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 ${placeBid ? "" : "hidden"}`}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "auto",
              maxWidth: "600px",
              padding: "4px",
            }}
            className="relative w-full h-auto max-w-2xl p-4"
          >
            <div
              style={{
                position: "relative",
                backgroundColor: "white",
                borderRadius: "8px",
                boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
              }}
              className="relative bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "6px 12px",
                  borderBottom: "1px solid #e6e6e6",
                }}
                className="flex justify-between items-center px-6 py-4 border-b border-gray-100 dark:border-t-gray-800"
              >
                <h5
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "800",
                    color: "black",
                  }}
                  className="text-xl font-bold"
                >
                  ❤️ Hear from Clients
                </h5>
                <button
                  style={{
                    color: "#718096",
                    backgroundColor: "transparent",
                    ":hover": { backgroundColor: "#edf2f7", color: "#2d3748" },
                    borderRadius: "8px",
                    fontSize: "1rem",
                    padding: "0.375rem 0.75rem",
                    marginLeft: "auto",
                  }}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-lg p-1.5 ms-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                >
                  <IoMdClose style={{ fontSize: "1.8rem", color: "black" }} onClick={() => setPlaceBid(!placeBid)} />
                </button>
              </div>
              <div id="center" style={{ padding: "1.5rem", overflowY: "auto", maxHeight: "400px", color: "black" }} className="p-6 overflow-y-auto max-h-96">
                {dataArray.map((item, index) => (
                  <>
                    <div key={index} className="product-card1 mb-4">
                      {" "}
                      <h5 style={{ fontSize: "1rem", fontWeight: "600", marginBottom: "20px" }} className="text-xl font-semibold">
                        {item.heading}
                      </h5>
                      <p style={{ textAlign: "start", color: "black", fontWeight: "500" }}>{item.content}</p>
                      <img
                        src={item.image}
                        style={{
                          borderRadius: "0.75rem",
                          marginTop: "20px",
                          width: "10rem",
                          height: "10rem",
                          objectFit: "cover",
                        }}
                        alt="Your Image"
                      />
                      <div
                        style={{
                          paddingTop: "1rem",
                          borderTop: "1px solid #e6e6e6",
                          marginTop: "25px",
                        }}
                        className="pt-4 border-t dark:border-gray-700"
                      >
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
                          <p style={{ textAlign: "start", color: "black", fontWeight: "500", display: "flex", alignItems: "center" }}>
                            {item.name}
                            <span style={{ display: "flex", alignItems: "center", marginLeft: "10px", color: "rgb(111 118 133/var(--tw-text-opacity))" }}>
                              {" "}
                              —{" "}
                              <span class="mingcute--location-fill" style={{ fontSize: "19px", marginRight: "5px", marginLeft: "7px" }}></span>
                              {item.from}
                            </span>
                          </p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
                          <span style={{ background: "#000000", borderRadius: "15px", display: "flex", alignItems: "center" }} className="bagde-flag1">
                            <span class="mingcute--airplane-line" style={{ fontSize: "20px", marginRight: "5px" }}></span>
                            {item.location}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
