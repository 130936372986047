import React, { useState, useEffect } from 'react';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import VisaListing from '../../pages/index/visa-listing';
import HealthListing from './health-listing';
import InsuranceListing from './insurance-listing';
import Select from 'react-select';
import Switcher from '../../components/switcher';
import { Icon } from '@iconify/react';
import airplaneTakeoffDuotone from '@iconify/icons-ph/airplane-takeoff-duotone';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { data } from '../../data/data';
import {
  LuClock,
  LuCalendarDays,
  RiDragDropLine,
  LuSearch,
  AiOutlineAppstore,
  AiOutlineStar,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  PiBrowsersLight,
  MdOutlineSportsVolleyball,
  AiOutlinePieChart,
  LuMusic4,
  TbCameraPlus,
  PiRocketLaunchBold,
  FaImages,
  ImImages,
  BiImage,
  BiImages,
  FaSchool,
  SiStudyverse,
  LuSchool,
  MdPlace,
  FcServices,
  GrServices,
  GrLanguage,
  FaLanguage,
  CgClose,
} from "../../assets/icons/vander";
import Comingsoon from '../special/comingsoon';
import UniversityListing from './university-listing';
import { Globe } from 'react-feather';
import { Delete } from 'feather-icons-react/build/IconComponents';
import PrPathwayListing from './pr-pathway-listing';
import ConsultantsListing from './consultants-listing';
import LanguagesListing from './languages-listing';
import CreditsLoanListing from './credits/loan-listing';
import CheckMyVisa from './check-my-visa';
import axios from 'axios';
import { usePlacesWidget } from 'react-google-autocomplete';
import { REST_API_END_POINT, REST_API, MAP_API_KEY } from '../../constants/DefaultValue';



const Languages = [
  { id: 1, value: 'English', label: 'English' },
  { id: 2, value: 'German', label: 'German' },
  { id: 3, value: 'Dutch', label: 'Dutch' },
  { id: 4, value: 'French', label: 'French' },
  { id: 5, value: 'Danish', label: 'Danish' },
  { id: 6, value: 'Estonian', label: 'Estonian' },
  { id: 7, value: 'Russian', label: 'Russian' },
  { id: 8, value: 'Germany', label: 'Germany' },
  { id: 9, value: 'Hausa', label: 'Hausa' },
  { id: 10, value: 'Spanish', label: 'Spanish' },
  { id: 11, value: 'Portuguese', label: 'Portuguese' },
  { id: 12, value: 'Hindi', label: 'Hindi' },
  { id: 13, value: 'Malayalam', label: 'Malayalam' },
];
const courseData = [
  'BA English',
  'BA Hindi',
  'BCA',
  'Information Technology',
  'Medicine',
  'Computer Science',
  'Business Administration',
  'Mechanical Engineering',
  'Psychology',
  'Electrical Engineering',
  'Law',
  'Economics',
  'Nursing',
  'Data Science'
];


const customStyles = {
  control: (styles, { isFocused, isHovered }) => ({
    ...styles,
    backgroundColor: 'transparent',
    marginTop: "-7px",
    color: 'black !important',
    placeholder: {
      color: 'black',
    },
    borderColor: isHovered ? 'white' : 'transparent',
    boxShadow: isHovered ? '0 0 0 2px white' : 'none',
    borderWidth: 0,
    outline: 'none',
    height: '30px',
  }),

  menu: provided => ({
    ...provided,
    marginLeft: '-35px',
    zIndex: 999,
  }),

  option: (styles, { isFocused }) => ({
    ...styles,

    backgroundColor: 'white',
    color: 'black',
    zIndex: isFocused ? 999 : 999,
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'black',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: 'black',
    '.css-tj5bde-Svg': {
      color: 'black',
    },
  }),
  input: (styles) => ({
    ...styles,
    color: 'black',
  }),
};
const VISA_REQUIREMENT = [
  { id: 0, value: 'ALL' },
  { id: 1, value: 'Visa Required' },
  { id: 2, value: 'Visa On Arrival' },
  { id: 3, value: 'eVisa' },
  { id: 4, value: 'Visa Free' },
  { id: 5, label: 'eTA' },
]
const navItems = [
  // { text: "All", color: "black", id: 0 },
  { text: "Travel", color: "black", id: 1 },
  { text: "Work", color: "black", id: 2 },
  { text: "Student", color: "black", id: 3 },
];
// const language=
//   [
//     { id: '0', label: 'All' ,color: "black"},
//     { id: '1', label: 'Arabic' ,color: "black"},
//     { id: '2', label: 'CAE' ,color: "black"},
//     { id: '3', label: 'French',color: "black" },
//     { id: '4', label: 'German',color: "black" },
//     { id: '5', label: 'Hindi' ,color: "black"},
//     { id: '6', label: 'IELTS',color: "black" },
//     { id: '7', label: 'Japanese',color: "black" },
//     { id: '8', label: 'Korean',color: "black" },
//     { id: '9', label: 'Mandarin' ,color: "black"},
//     { id: '10', label: 'NAATI' ,color: "black"},
//     { id: '11', label: 'OET',color: "black" },
//     { id: '12', label: 'PTE',color: "black" },
//     { id: '13', label: 'Russian',color: "black" },
//     { id: '14', label: 'Spanish' ,color: "black"},
//     { id: '15', label: 'TOEFL',color: "black" },
//     { id: '16', label: 'Urdu' ,color: "black"}
//   ]
const language = [
  { id: '0', label: 'All', color: 'black' },
  { id: '1', label: 'Arabic', color: 'black' },
  { id: '2', label: 'French', color: 'black' },
  { id: '3', label: 'German', color: 'black' },
  { id: '4', label: 'Hindi', color: 'black' },
  { id: '5', label: 'Japanese', color: 'black' },
  { id: '6', label: 'Korean', color: 'black' },
  { id: '7', label: 'Mandarin', color: 'black' },
  { id: '8', label: 'Russian', color: 'black' },
  { id: '9', label: 'Spanish', color: 'black' },
  { id: '10', label: 'Urdu', color: 'black' }
];


const visaServices=[
  {id:'0' ,label: 'All Services' ,color: "black"},
  {id:'1' ,label: 'Travel Visa',color: "black" },
  {id:'2' ,label: 'Work Visa',color: "black" },
  {id:'3' ,label: 'Student Visa',color: "black" },
  {id:'4' ,label: 'Family Visa',color: "black"},
  {id:'5' ,label: 'Permanent Residency Visa' ,color: "black"},
  {id:'6' ,label: 'Citizenship',color: "black" },
  {id:'7' ,label: 'Other Visa & Services',color: "black" },
]
const languageData = [
  { id: '0', label: 'All', color: 'black' },
  { id: '1', label: 'IELTS', color: 'black' },
  { id: '2', label: 'PTE', color: 'black' },
  { id: '3', label: 'OET', color: 'black' },
  { id: '4', label: 'TOEFL', color: 'black' },
  { id: '5', label: 'CAE', color: 'black' },
  { id: '6', label: 'NAATI', color: 'black' },
  { id: '7', label: 'Arabic', color: 'black' },
  { id: '8', label: 'French', color: 'black' },
  { id: '9', label: 'German', color: 'black' },
  { id: '10', label: 'Hindi', color: 'black' },
  { id: '11', label: 'Japanese', color: 'black' },
  { id: '12', label: 'Korean', color: 'black' },
  { id: '13', label: 'Mandarin', color: 'black' },
  { id: '14', label: 'Russian', color: 'black' },
  { id: '15', label: 'Spanish', color: 'black' },
  { id: '16', label: 'Urdu', color: 'black' }
];


const creditType = [
  { id: '0', label: 'All', color: 'black' },
  { id: '1', label: 'Personal', color: 'black' },
  { id: '2', label: 'Student', color: 'black' },
  { id: '3', label: 'Vehicle', color: 'black' },
  { id: '4', label: 'Credit card', color: 'black' }
];



// const languageDatas = languageData.sort((a, b) => a.label.localeCompare(b.label));
export default function HomeVisa() {
  const { tab } = useParams();
  const [activeIndex, setIndex] = useState((tab));
  const [typeId, setTypeId] = useState(1)
  const [visaSelect, setVisaSelect] = useState(VISA_REQUIREMENT[0])
  const [searchPlaceholder, setSearchPlaceholder] = useState("Where to?...");
  const [countryDetails, setCountryDetails] = useState({})
  const [countryDetailsSearch, setCountryDetailsSearch] = useState({})
  const [insSearch, setInsSearch] = useState(false)
  const [selectedData, setSelectedData] = useState({})
  const [courses, setCourses] = useState([])
  const [universities, setUniversities] = useState([])
  const [universitySearch, setUniversitySearch] = useState(true)
  const [CountrySelected, setCountrySelected] = useState(true);
  const navigate=useNavigate()
  // console.log("universitySearch ====================",universitySearch);
  const [courseInput, setCourseInput] = useState('')
  const [SearchInputValue, setSearchInputValue] = useState('');
  const [SearchInputValueCountry, setSearchInputValueCountry] = useState('');
  const [SearchInputValueCourse, setSearchInputValueCourse] = useState('');
  const [searchOptions, setSearchOptions] = useState([]);
  const [searchOptionsCountry, setSearchOptionsCountry] = useState([]);
  const [searchOptionsCourse, setSearchOptionsCourse] = useState([]);
  const [searchOptionsCourses, setSearchOptionsCourses] = useState([]);
 
  const [showNoSearchOptionsMessage, setShowNoSearchOptionsMessage] = useState(false);
  const [showNoSearchOptionsMessages, setShowNoSearchOptionsMessages] = useState(false);
  const [showNoSearchOptionsMessagess, setShowNoSearchOptionsMessagess] = useState(false);
  const [SearchInputServiceValue, setSearchServiceInputValue] = useState('');
  const [searchServiceOptions, setSearchServiceOptions] = useState([]);
  const [showNoSearchServiceOptionsMessage, setShowNoSearchServiceOptionsMessage] = useState(false);
  const [SearchInputLanguageValue, setSearchLanguageInputValue] = useState('');
  const [searchLanguageOptions, setSearchLanguageOptions] = useState([]);
  const [showNoSearchLanguageOptionsMessage, setShowNoSearchLanguageOptionsMessage] = useState(false);
  const [SearchInputLanguageConsultValue, setSearchLanguageConsultInputValue] = useState('');
  const [searchLanguageConsultOptions, setSearchLanguageConsultOptions] = useState([]);
  const [showNoSearchLanguageConsultOptionsMessage, setShowNoSearchLanguageConsultOptionsMessage] = useState(false);
  const [refresh, setRefresh] = useState(false)
  const [activIndex, setActivIndex] = useState(0);
  const [activIndexConsultant, setActivIndexConsultant] = useState(0);
  const [activIndexConsultantService, setActivIndexConsultantService] = useState(0);
  const [activIndexLanguage, setActivIndexLanguages] = useState(0);
  const [selectedItem, setSelectedItem] = useState(activIndex);
  const [activeTab, setActiveTab] = useState(0);
  const [insTypeId, setInsTypeId] = useState(0)
  const [consLangId, setConsLangId] = useState(0)
  const [consSerId, setConsSerId] = useState(0)
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenLang, setDropdownOpenLang] = useState(false);
  const [dropdownOpenservice, setDropdownOpenservice] = useState(false);
  const [address, setAddress] = useState({
    address: '',
    city: '',
    state: '',
    zip_code: '',
    search_google_enabled: false,
  });

  const [propertyData, setPropertyData] = useState({
    lot_size: '',
    square_feet: '',
    year_built: '',
    bedrooms: '',
    bathrooms: '',
    heating: '',
    cooling: '',
    parking: '',
  });

  const handleFetch = (address, zip_code) => {
    const api = MAP_API_KEY;
    axios
      .get(api)
      .then((result) => {
        if (
          result?.data?.bundle.length &&
          result.data.bundle[0].address.zip &&
          result.data.bundle[0].address.house
        ) {
          axios
            .get(api)
            .then((data) => {
              if (
                data &&
                Object.keys(data).length === 0 &&
                Object.getPrototypeOf(data) === Object.prototype
              ) {
                console.log('Appointment Data Outside failed');
              } else if (
                data?.data?.bundle.length ||
                data.data.bundle[0]?.areas.length ||
                data.data.bundle[0]?.building.length ||
                data.data.bundle[0]?.garages.length
              ) {
                setPropertyData({
                  ...propertyData,
                  lot_size: data.data.bundle[0].lotSizeSquareFeet,
                  square_feet: data.data.bundle[0].areas[0]?.areaSquareFeet,
                  year_built: data.data.bundle[0].building[0]?.yearBuilt,
                  bedrooms: data.data.bundle[0].building[0]?.bedrooms,
                  bathrooms: data.data.bundle[0].building[0]?.fullBaths,
                  heating: data.data.bundle[0].building[0]?.heating,
                  cooling: data.data.bundle[0].building[0]?.airConditioning,
                  parking: data.data.bundle[0].garages.length,
                });
              }
            });
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const { ref: inputRef, ...rest } = usePlacesWidget({
    onPlaceSelected: (res) => {
      if (typeof res.address_components !== 'undefined') {
        const address_components = res.address_components;

        const address_1 = address_components.find(
          (row) => row.types.indexOf('street_number') !== -1
        );
        const address_2 = address_components.find(
          (row) => row.types.indexOf('route') !== -1
        );

        let addressValue = '';
        if (address_1) addressValue = address_1.long_name;
        if (address_2) addressValue += ' ' + address_2.long_name;

        const city = address_components.find(
          (row) => row.types.indexOf('locality') !== -1
        )?.long_name || '';

        const state = address_components.find(
          (row) => row.types.indexOf('administrative_area_level_1') !== -1
        )?.short_name || '';

        const zip_code = address_components.find(
          (row) => row.types.indexOf('postal_code') !== -1
        )?.long_name || '';

        setAddress({
          address: addressValue,
          city: city,
          state: state,
          zip_code: zip_code,
          search_google_enabled: false,
        });

        handleFetch(addressValue, zip_code);
      }
    },
    options: {
      types: ['geocode'],
      //componentRestrictions: { country:['us', 'ca', 'uy'] },
    },
  });

  ////////////////////
  const fetchCountryDetailsByName = (country_name) => {
    axios.get(`${REST_API_END_POINT}country/${country_name}`)
      .then((res) => {
        if (res.data.status === 1) {
          setCountryDetails(res.data.data)
          localStorage.setItem('country_preference', JSON.stringify(res.data.data))
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // useEffect(() => {
  //   const fetchCountryByIP = async () => {
  //     try {
  //       const response = await fetch('http://ip-api.com/json');
  //       const data = await response.json();
  //       fetchCountryDetailsByName(data?.country)
  //     } catch (error) {
  //       console.error('Error fetching country:', error);
  //     }
  //   };

  //   fetchCountryByIP();
  // }, []);


  useEffect(() => {

    if (activeIndex === 'check-my-visa') {
      setSearchPlaceholder("Choose Country to Check Your Visa");
    } else if (activeIndex === 'pr-pathway') {
      setSearchPlaceholder("Choose country for your path to permanent residency");
    } else if (activeIndex === 'insurance') {
      setSearchPlaceholder("Choose country you are traveling to");
    } else if (activeIndex === 'universities') {
      setSearchPlaceholder("Search Universities?...");
    } else if (activeIndex === 'credits-loan') {
      setSearchPlaceholder("Discover Credits/Loans For You...");
    } else {
      setSearchPlaceholder("Where to?...");
    }
  }, [activeIndex]);


  const handleRemove = () => {
    setCourses([])
    setCountryDetailsSearch({})
    setCourseInput('')
    setInsSearch(false)
    setRefresh(!refresh)
    setSearchInputValue('')
    setSearchInputValueCountry('')
    setSearchInputValueCourse('')
    setShowNoSearchOptionsMessage(false)
    setShowNoSearchOptionsMessages(false)
    setShowNoSearchOptionsMessagess(false)
    setSearchOptions([])
    setSearchOptionsCountry([])
    setSearchOptionsCourse([])
    setSearchOptionsCourses([])
    setSearchServiceInputValue('')
    setSearchServiceOptions([])
    setSearchLanguageConsultOptions([])
    setSearchLanguageConsultInputValue('')
    setUniversitySearch(true)
  }
  const handleRemoveConsultant = () => {
    // setCourses([])
    // setCountryDetailsSearch({})
    // setCourseInput('')
    // setInsSearch(false)
    setRefresh(!refresh)
    setSearchInputValue('')
    // setSearchInputValueCountry('')
    setSearchInputValueCourse('')
    setShowNoSearchOptionsMessage(false)
    setShowNoSearchOptionsMessages(false)
    setShowNoSearchOptionsMessagess(false)
    setSearchOptions([])
    // setSearchOptionsCountry([])
    setSearchOptionsCourse([])
    setSearchOptionsCourses([])
    // setSearchServiceInputValue('')
    setSearchServiceOptions([])
    setSearchLanguageConsultOptions([])
    setSearchLanguageConsultInputValue('')
    setUniversitySearch(true)
  }
  const handleRemoveUniversityCountry = () => {
    setCourses([])
    setCountryDetailsSearch({})
    setCourseInput('')
    setInsSearch(false)
    setRefresh(!refresh)
    setSearchInputValue('')
    setSearchInputValueCountry('')
    setSearchInputValueCourse('')
    setShowNoSearchOptionsMessage(false)
    setShowNoSearchOptionsMessages(false)
    setShowNoSearchOptionsMessagess(false)
    setSearchOptions([])
    setSearchOptionsCountry([])
    setSearchOptionsCourse([])
    setSearchOptionsCourses([])
    setSearchServiceInputValue('')
    setSearchServiceOptions([])
    setSearchLanguageConsultOptions([])
    setSearchLanguageConsultInputValue('')
    setUniversitySearch(true)
  }
  const handleRemoveUniversity = () => {
    setCourses([])
    // setCountryDetailsSearch({})
    setCourseInput('')
    // setInsSearch(false)
    setRefresh(!refresh)
    setSearchInputValue('')
    // setSearchInputValueCountry('')
    setSearchInputValueCourse('')
    setShowNoSearchOptionsMessage(false)
    setShowNoSearchOptionsMessages(false)
    // setShowNoSearchOptionsMessagess(false)
    setSearchOptions([])
    // setSearchOptionsCountry([])
    setSearchOptionsCourse([])
    setSearchOptionsCourses([])
    setSearchServiceInputValue('')
    setSearchServiceOptions([])
    setSearchLanguageConsultOptions([])
    setSearchLanguageConsultInputValue('')
    setUniversitySearch(true)
  }
  useEffect(() => {
    setSearchInputValue('')
    setSearchInputValueCountry('')
    setSearchInputValueCourse('')
    setSelectedData({})
    setCountryDetailsSearch({})
    setShowNoSearchOptionsMessage(false)
    setShowNoSearchOptionsMessages(false)
    setShowNoSearchOptionsMessagess(false)
    setSearchOptions([])
    setSearchOptionsCountry([])
    setSearchOptionsCourse([])
    setSearchOptionsCourses([])
  }, [activeIndex])

  const handleItemClick = (index, type_id) => {
    setActiveTab(index);
    setSearchInputValue('')
  };
  const handleItemClickTab = (index, type_id) => {
    setInsTypeId(type_id)
    setActivIndex(index);
    // setSearchInputValue('')
  };
  const handleItemClickTabLanguages = (index, type_id) => {
    if (index >= 0 && index < languageData.length) {
      // setConsLangId(type_id);
      let typeID=+type_id
      console.log("type_idtype_idtype_id", index);
      console.log("type_idtype_idtype_id", typeID);

      
      setActivIndexLanguages(typeID);
      // Other logic related to selecting a language
    } else {
      console.error('Invalid index:', index);
    }
  };
  const handleItemClickTabConsultant = (index, type_id) => {
    if (index >= 0 && index < language.length) {
      setConsLangId(type_id);
      setActivIndexConsultant(index);
      // Other logic related to selecting a language
    } else {
      console.error('Invalid index:', index);
    }
  };
  const handleItemClickTabConsultantService = (index, type_id) => {
    if (index >= 0 && index < visaServices.length) {
      setConsSerId(type_id);
      setActivIndexConsultantService(index);
      // Other logic related to selecting a language
    } else {
      console.error('Invalid index:', index);
    }
  };
  console.log("countryDetailscountryDetails1",countryDetailsSearch); 
  console.log("countryDetailscountryDetails1",countryDetails); 
  const handleFindCourse = async (e) => {
    const value = e.target.value;
    setSearchInputValueCourse(value);
    if (value.trim() !== '') {
      let response = []
      //  response = await axios.post(`${REST_API_END_POINT}university/get-all-list`,{search:value})
      response = await axios.post(`${REST_API_END_POINT}university/get-all-course-with-university`, { search: value })
      if (response.data.status === 1) {
        console.log("response.data?.data",response.data?.data);
        
        // setSearchOptionsCourse([])
        // let filteredData=response.data?.data
        let filteredData=[]
        console.log("countryDetailscountryDetails1",countryDetailsSearch);
        if(insSearch){
           filteredData = response?.data?.data
        .filter(d => d.status === 1 )
        .filter(d => d.location?.toLowerCase().includes(countryDetailsSearch?.name?.toLowerCase()));
        console.log('qqqqqqqqqqqqqqqqqqq',filteredData);
        
      }
        else{
          console.log("countryDetailscountryDetails",countryDetails);
          
           filteredData = response?.data?.data
          .filter(d => d.status === 1 )
          .filter(d => d.location?.toLowerCase().includes(countryDetails.name.toLowerCase()));
          console.log('qqqqqqqqqqqqqqqqqqq',filteredData);
        }
        setShowNoSearchOptionsMessages(filteredData.length === 0);
   

        
        const filteredOptions = filteredData.filter(data => {
          const name = data.name ? data.name.toLowerCase() : "";
          const courseName = data.course_name ? data.course_name.toLowerCase() : "";
          return name.includes(value.toLowerCase()) || courseName.includes(value.toLowerCase());
        });

      //   const filteredOptions = filteredData.filter(data => {
      //     const name = data.name && data.university_name
      //         ? `${data.name.toLowerCase()} (${data.university_name.toLowerCase()})`
      //         : ""; // Handle undefined values
      
      //     const courseName = data.course_name && data.university_name
      //         ? `${data.course_name.toLowerCase()} (${data.university_name.toLowerCase()})`
      //         : ""; // Handle undefined values
      
      //     // Check if either name or courseName includes the search value
      //     return name.includes(value.toLowerCase()) || courseName.includes(value.toLowerCase());
      // });
        const sortedOptions = filteredOptions.sort((a, b) => {
          const nameA = a.name ? a.name.toLowerCase() : "";
          const nameB = b.name ? b.name.toLowerCase() : "";
          return nameA.localeCompare(nameB);
        });
        console.log("sortedOptions===========",sortedOptions);
        // const courseDataLowercase = courseData.map(course => course.toLowerCase());
        // const filteredSortedOptions = sortedOptions.filter(option => 
        //   courseDataLowercase.includes(option.course_name.toLowerCase())
        // );

        const courseDataLowercase = courseData.map(course => course.toLowerCase());

        // Create a Set to keep track of which courses we have already added from courseData
        const selectedCourses = new Set();
        
        // Filter courseData based on whether the course_name exists in sortedOptions
        const filteredCourseData = courseData.filter(course => {
          const courseLowerCase = course.toLowerCase();
        
          // Check if there is a matching course_name in sortedOptions
          const matchExists = sortedOptions.some(option => 
            option.course_name.toLowerCase() === courseLowerCase
          );
        
          // If match exists and course is not already added to the set, add it
          if (matchExists && !selectedCourses.has(courseLowerCase)) {
            selectedCourses.add(courseLowerCase); // Mark this course as added
            return true; // Keep this course in the filtered result
          }
        
          return false; // Exclude if no match or already added
        });
        
        console.log("Filtered courseData based on sortedOptions:", filteredCourseData);
        // setSearchOptionsCourse(sortedOptions);
        setSearchOptionsCourse(filteredCourseData);
        setSearchOptionsCourses(sortedOptions);
        setShowNoSearchOptionsMessages(filteredOptions.length === 0);
      }
    } else {
      setSearchOptionsCourse([]);
      setSearchOptionsCourses([])
      setShowNoSearchOptionsMessages(false);
    }
  }
  // console.log("universitySearch111111111111", universitySearch);
  const handleSearchInputChange = async (e) => {
    let value = null
    value = e.target.value;
    // console.log("value11111111111", value.length === 0);
    // console.log("universitySearch11111111111", universitySearch);
    // console.log("unive11111111111", value);

    if (SearchInputValue=='') {
      setUniversitySearch(false)
      setCountrySelected(false)
      
    } else {
      setUniversitySearch(true)
      setCountrySelected(true)
    }
    // console.log("universitySearch111111111111", universitySearch);

    setSearchInputValue(value);
    if (value.trim() !== '') {
      let response = []

      if (activeIndex === 'visa') {
        response = await axios.post(`${REST_API_END_POINT}country/list-for-user`, {
          search: value, c_id: countryDetails.id, typeId, visa_required_type: visaSelect.id
        });
      }
      else if (activeIndex === 'check-my-visa') {
        response = await axios.post(`${REST_API_END_POINT}country-listing`, { search: value });
      }
      else if (activeIndex === 'pr-pathway') {
        response = await axios.post(`${REST_API_END_POINT}pr_pathway/get-all-pr`, { search: value })
      }
      else if (activeIndex === 'insurance') {
        response = await axios.post(`${REST_API_END_POINT}insurance/get-insurance-company-search`, { search: value })
        
      }
      else if (activeIndex === 'universities') {
        response = await axios.post(`${REST_API_END_POINT}university/get-all-list`, { search: value })
        //  response = await axios.post(`${REST_API_END_POINT}university/get-all-course-with-university`,{search:value})

      }
      else if (activeIndex === 'credits-loan') {
        response = await axios.post(`${REST_API_END_POINT}bank/search-name`, { search: value })
        // response = await axios.get(`${REST_API_END_POINT}country-list/user-navbar/${value}`)
      } else if (activeIndex === 'consultants') {
        response = await axios.post(`${REST_API_END_POINT}consultant/get-all-consultant`, { search: value })
      }
      if (response.data.status === 1) {
        if (activeIndex === 'insurance') {
          // setSearchOptions(response.data?.data);
          // const filteredData=response.data?.data
          const filteredData=response.data?.data?.data

          // if (insTypeId === 1) {
          //   // Travel
          //   filteredData = filteredData.filter(d => d.insurance_type?.split(',').includes('1'));
          // } else if (insTypeId === 2) {
          //   // Work
          //   filteredData = filteredData.filter(d => d.insurance_type?.split(',').includes('2'));
          // } else if (insTypeId === 3) {
          //   // Student 
          //   filteredData = filteredData.filter(d => d.insurance_type?.split(',').includes('3'));
          // }
  
          // Log the final filtered data
          console.log("Filtered data:-----------------", filteredData);

          setSearchOptions(filteredData);
          // setSearchOptions(response.data?.data);
          setShowNoSearchOptionsMessage(filteredData.length === 0);
        }
        
        else if (activeIndex === 'credits-loan') {
          console.log("Filtered data:-----------------",response.data?.data);
          let filteredData=[]
          if(insSearch){
             filteredData = response?.data?.data
          .filter(d => d.status === 1 )
          .filter(d => d.location?.toLowerCase().includes(countryDetailsSearch.name.toLowerCase()));
          // setSearchOptions(filteredData)
        }
          else{
             filteredData = response?.data?.data
            .filter(d => d.status === 1 )
            .filter(d => d.location?.toLowerCase().includes(countryDetails.name.toLowerCase()));
            // setSearchOptions(filteredData)
          }
          setShowNoSearchOptionsMessage(filteredData.length === 0);


          const filteredOptions = filteredData.filter(data => {
            const name = data.name ? data.name.toLowerCase() : "";
            const courseName = data.course_name ? data.course_name.toLowerCase() : "";
            return name.includes(value.toLowerCase()) || courseName.includes(value.toLowerCase());
          });
          const sortedOptions = filteredOptions.sort((a, b) => {
            const nameA = a.name ? a.name.toLowerCase() : "";
            const nameB = b.name ? b.name.toLowerCase() : "";
            return nameA.localeCompare(nameB);
          });
          setSearchOptions(sortedOptions);
          setShowNoSearchOptionsMessage(filteredOptions.length === 0);


        }
        else if (activeIndex === 'consultants') {
          console.log("Filtered data:-----------------",response.data?.data);
          let filteredData=[]
          if(insSearch){
             filteredData = response?.data?.data
          .filter(d => d.status === 1 )
          .filter(d => d.location?.toLowerCase().includes(countryDetailsSearch.name.toLowerCase()));
          // setSearchOptions(filteredData)
        }
          else{
             filteredData = response?.data?.data
            .filter(d => d.status === 1 )
            .filter(d => d.location?.toLowerCase().includes(countryDetails.name.toLowerCase()));
            // setSearchOptions(filteredData)
          }
          setShowNoSearchOptionsMessage(filteredData.length === 0);


          const filteredOptions = filteredData.filter(data => {
            const name = data.name ? data.name.toLowerCase() : "";
            const courseName = data.course_name ? data.course_name.toLowerCase() : "";
            return name.includes(value.toLowerCase()) || courseName.includes(value.toLowerCase());
          });
          const sortedOptions = filteredOptions.sort((a, b) => {
            const nameA = a.name ? a.name.toLowerCase() : "";
            const nameB = b.name ? b.name.toLowerCase() : "";
            return nameA.localeCompare(nameB);
          });
          setSearchOptions(sortedOptions);
          setShowNoSearchOptionsMessage(filteredOptions.length === 0);


        }
        else if (activeIndex === 'universities') {
          console.log("Filtered data:-----------------",response.data?.data);
          let filteredData=[]
          if(insSearch){
             filteredData = response?.data?.data
          .filter(d => d.status === 1 )
          .filter(d => d.location?.toLowerCase().includes(countryDetailsSearch?.name?.toLowerCase()));
          setSearchOptions(filteredData)
        }
          else{
             filteredData = response?.data?.data
            .filter(d => d.status === 1 )
            .filter(d => d.location?.toLowerCase().includes(countryDetails?.name?.toLowerCase()));
            setSearchOptions(filteredData)
          }
          setShowNoSearchOptionsMessage(filteredData.length === 0);
        }
        
        else {
console.log("response.data?.dataresponse.data?.data",response.data?.data);


          const filteredOptions = response.data?.data?.filter(data => {
           
            
            const name = data.name ? data.name.toLowerCase() : "";
            const courseName = data.course_name ? data.course_name.toLowerCase() : "";
            return name.includes(value.toLowerCase()) || courseName.includes(value.toLowerCase());
          });
          const sortedOptions = filteredOptions.sort((a, b) => {
            const nameA = a.name ? a.name.toLowerCase() : "";
            const nameB = b.name ? b.name.toLowerCase() : "";
            return nameA.localeCompare(nameB);
          });
          setSearchOptions(sortedOptions);
          setShowNoSearchOptionsMessage(filteredOptions.length === 0);
          // console.log("filteredOptions.length === 0====================",filteredOptions.length);
          if (filteredOptions.length === 0) {
            setUniversitySearch(true);

          }
        }

      }
      
    } else {
      setSearchOptions([]);
      setShowNoSearchOptionsMessage(false);
    }
  };
  const handleSearchInputChangeCountry = async (e) => {
    let value = null
    value = e.target.value;
    if (SearchInputValueCountry=='') {
      setUniversitySearch(false)
      
    } else {
      setUniversitySearch(true)
    }

    setSearchInputValueCountry(value);
  

       if (activeIndex === 'universities') {
      let  response = await axios.get(`${REST_API_END_POINT}country-list/user-navbar/${value}`)
      
      if (response.data.status === 1) {
        
          const filteredData=response.data.data
          console.log("Filtered data:-----------------", filteredData);

          setSearchOptionsCountry(filteredData);
          setShowNoSearchOptionsMessagess(filteredData.length === 0);
          console.log("Filtered data:-----------------1", filteredData.length === 0);
        } else {
          setSearchOptionsCountry([]);
          console.log("Filtered data:-----------------12", response.data.length === 0);
          setShowNoSearchOptionsMessagess(response.data.status===0);
        }
      
    }
     else if (activeIndex === 'credits-loan') {
      let  response = await axios.get(`${REST_API_END_POINT}country-list/user-navbar/${value}`)
      
      if (response.data.status === 1) {
        
          const filteredData=response.data.data
          console.log("Filtered data:-----------------", filteredData);

          setSearchOptionsCountry(filteredData);
          setShowNoSearchOptionsMessagess(filteredData.length === 0);
          console.log("Filtered data:-----------------1", filteredData.length === 0);
        } else {
          setSearchOptionsCountry([]);
          console.log("Filtered data:-----------------12", response.data.length === 0);
          setShowNoSearchOptionsMessagess(response.data.status===0);
        }
      
    }
    else if (activeIndex === 'consultants') {
      let  response = await axios.get(`${REST_API_END_POINT}country-list/user-navbar/${value}`)
      
      if (response.data.status === 1) {
        
          const filteredData=response.data.data
          console.log("Filtered data:-----------------", filteredData);

          setSearchOptionsCountry(filteredData);
          setShowNoSearchOptionsMessagess(filteredData.length === 0);
          console.log("Filtered data:-----------------1", filteredData.length === 0);
        } else {
          setSearchOptionsCountry([]);
          console.log("Filtered data:-----------------12", response.data.length === 0);
          setShowNoSearchOptionsMessagess(response.data.status===0);
        }
      
    }
    else if (activeIndex === 'language-consultants') {
      // let  response = await axios.get(`${REST_API_END_POINT}language/location-list${value}`)
      let  response = await axios.get(`${REST_API_END_POINT}country-list/user-navbar/${value}`)
      if (response.data.status === 1) {
        
          const filteredData=response.data.data
          console.log("Filtered data:-----------------", filteredData);

          setSearchOptionsCountry(filteredData);
          setShowNoSearchOptionsMessagess(filteredData.length === 0);
          console.log("Filtered data:-----------------1", filteredData.length === 0);
        } else {
          setSearchOptionsCountry([]);
          console.log("Filtered data:-----------------12", response.data.length === 0);
          setShowNoSearchOptionsMessagess(response.data.status===0);
        }
      
    }
     else {
      setSearchOptionsCountry([]);
      setShowNoSearchOptionsMessagess(false);
    }
  }
  

  const handleSearchOptionClick = (option) => {
    if (activeIndex === 'credits-loan') {
      let name = option.loan_name ? `${option.loan_name} (${option.name})` : option.name;
      setSearchInputValue(name)
      
    } else {
      setSearchInputValue(option.name);
    }
    if (activeIndex === 'universities') {
      axios.post(`${REST_API_END_POINT}university/get-all-course`, { u_id: option?.id, getUniverity: true })
        .then((res) => {
          if (res.data.status == 1) {
            

            setCourses(res.data.data)
            setSearchOptions([]);
            setShowNoSearchOptionsMessage(false);
            setSelectedData(option)
          }
        })
    }
     else {
      setSelectedData(option)
      setSearchOptions([]);
      setShowNoSearchOptionsMessage(false);
    }
    if (activeIndex === 'insurance') {
      if (activeIndex === 'insurance') {
          // setCountryDetails(option)
      setInsSearch(true)
      setCountryDetailsSearch(option)
    } 
    else {
      setCountryDetailsSearch({})
      setInsSearch(false)
      setSelectedData(option)
      setSearchOptions([]);
      setShowNoSearchOptionsMessage(false);
    }
  }

  };
  const handleSearchOptionClickCourse = (option) => {
    
    setSearchInputValueCourse(option)
    // navigate(`/university-details/${option.course_id}/${activeIndex}`)
   
    
    // return
    
    // if (activeIndex === 'universities') {
    //   axios.post(`${REST_API_END_POINT}university/get-all-university-with-course`, { id: option?.university_id, name: option?.name })
    //     .then((res) => {
    //       if (res.data.status == 1) {
    //         setCourses(res.data.data)
    //         setUniversities(res.data.university)
    //         setSearchOptionsCourse([])
    //         setShowNoSearchOptionsMessage(false);
    //         setSelectedData(res.data.university)
    //       }
    //     })
    // } else {
    //   // setSelectedData(option)

    //   setSearchOptions([]);
    //   setShowNoSearchOptionsMessage(false);
    // }
    if (activeIndex === 'universities') {
      let value=option
    axios.post(`${REST_API_END_POINT}university/get-all-course-with-university`, { search: value })
    .then((response) => {
    if (response.data.status === 1) {
      console.log("response.data?.data",response.data?.data);
      // setSearchOptionsCourse([])
      // setSearchOptionsCourse([])
      // let filteredData=response.data?.data
      let filteredData=[]
      console.log("countryDetailscountryDetails1",countryDetailsSearch);
      if(insSearch){
         filteredData = response?.data?.data
      .filter(d => d.status === 1 )
      .filter(d => d.location?.toLowerCase().includes(countryDetailsSearch?.name?.toLowerCase()));
      console.log('qqqqqqqqqqqqqqqqqqq',filteredData);
      
    }
      else{
        console.log("countryDetailscountryDetails",countryDetails);
        
         filteredData = response?.data?.data
        .filter(d => d.status === 1 )
        .filter(d => d.location?.toLowerCase().includes(countryDetails.name.toLowerCase()));
        console.log('qqqqqqqqqqqqqqqqqqq',filteredData);
      }
      setShowNoSearchOptionsMessages(filteredData.length === 0);
 

      
      const filteredOptions = filteredData.filter(data => {
        const name = data.name ? data.name.toLowerCase() : "";
        const courseName = data.course_name ? data.course_name.toLowerCase() : "";
        return name.includes(value.toLowerCase()) || courseName.includes(value.toLowerCase());
      })

      const sortedOptions = filteredOptions.sort((a, b) => {
        const nameA = a.name ? a.name.toLowerCase() : "";
        const nameB = b.name ? b.name.toLowerCase() : "";
        return nameA.localeCompare(nameB);
      });
      console.log("sortedOptions===========",sortedOptions);
      setSearchOptionsCourse([])
      setSearchOptionsCourses(sortedOptions);
      setShowNoSearchOptionsMessages(filteredOptions.length === 0);
    }
  })} else {
    setSearchOptionsCourse([]);
    setSearchOptionsCourses([]);
    setShowNoSearchOptionsMessages(false);
  }
  };

  // console.log("selectedData", selectedData);
  const handleCourseClick = (option) => {
    setCourseInput(option.course_name)
    // setSelectedData(option)

    if (activeIndex === 'universities') {
      let value=option?.course_name
    axios.post(`${REST_API_END_POINT}university/get-all-course-with-university`, { search: value })
    .then((response) => {
    if (response.data.status === 1) {
      console.log("response.data?.data",response.data?.data);
      // setSearchOptionsCourse([])
      // setSearchOptionsCourse([])
      // let filteredData=response.data?.data
      let filteredData=[]
      console.log("countryDetailscountryDetails1",countryDetailsSearch);
      if(insSearch){
         filteredData = response?.data?.data
      .filter(d => d.status === 1 )
      .filter(d => d.location?.toLowerCase().includes(countryDetailsSearch?.name?.toLowerCase()));
      console.log('qqqqqqqqqqqqqqqqqqq',filteredData);
      
    }
      else{
        console.log("countryDetailscountryDetails",countryDetails);
        
         filteredData = response?.data?.data
        .filter(d => d.status === 1 )
        .filter(d => d.location?.toLowerCase().includes(countryDetails.name.toLowerCase()));
        console.log('qqqqqqqqqqqqqqqqqqq',filteredData);
      }
      setShowNoSearchOptionsMessages(filteredData.length === 0);
 

      
      const filteredOptions = filteredData.filter(data => {
        const name = data.name ? data.name.toLowerCase() : "";
        const courseName = data.course_name ? data.course_name.toLowerCase() : "";
        return name.includes(value.toLowerCase()) || courseName.includes(value.toLowerCase());
      })

      const sortedOptions = filteredOptions.sort((a, b) => {
        const nameA = a.name ? a.name.toLowerCase() : "";
        const nameB = b.name ? b.name.toLowerCase() : "";
        return nameA.localeCompare(nameB);
      });
      console.log("sortedOptions===========",sortedOptions);
      setSearchOptionsCourse([])
      setSearchOptionsCourses(sortedOptions);
      setShowNoSearchServiceOptionsMessage(filteredOptions.length === 0);
    }
  })} else {
    setSearchOptionsCourse([]);
    setSearchOptionsCourses([]);
    // setShowNoSearchServiceOptionsMessage(false);
  }




    setCourses([])
    setShowNoSearchServiceOptionsMessage(false)
  }
  const handleSearchOptionClickCountry = (option) => {
    setSearchInputValueCountry(option.name)
    setSearchOptionsCountry([])
    setCountryDetailsSearch(option)
    setInsSearch(true)
    setCountrySelected(true)
    setShowNoSearchServiceOptionsMessage(false)
  }



  // const handleServiceSearchInputChange = (e) => {
  //     const value = e.target.value;
  //     setSearchServiceInputValue(value);

  //     if (value.trim() !== '') {
  //        axios.post(`${REST_API_END_POINT}consultant/get-all-consultant`,{search:value})
  //        .then((res)=>{
  //         if(res.data.status===1){
  //           console.log(res.data.data,'-------------@@@@@@@@')
  //         setSearchServiceOptions(res.data?.data);
  //         setShowNoSearchServiceOptionsMessage(res.data?.data?.length === 0); 
  //         }
  //        })
  //     } else {
  //       setSearchServiceOptions([]);
  //         setShowNoSearchServiceOptionsMessage(false); 
  //     }
  // };

  const handleServiceSearchOptionClick = (option) => {
    setSearchInputValue(option.name);
    setSelectedData(option)
    setSearchServiceOptions([]);
    setShowNoSearchServiceOptionsMessage(false);
    setSearchOptions([]);
    setShowNoSearchOptionsMessage(false);
  };



  const handleLanguageSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchLanguageInputValue(value);

    if (value.trim() !== '') {
      const filteredOptions = Languages.filter(course =>
        course.label.toLowerCase().includes(value.toLowerCase())
      );
      setSearchLanguageOptions(filteredOptions.map(option => option.label));
      setShowNoSearchLanguageOptionsMessage(filteredOptions.length === 0);
    } else {
      setSearchLanguageOptions([]);
      setShowNoSearchLanguageOptionsMessage(false);
    }
  };

  const handleLanguageSearchOptionClick = (option) => {
    setSearchLanguageInputValue(option);
    setSearchLanguageOptions([]);
    setShowNoSearchLanguageOptionsMessage(false);
  };



  const handleLanguageConsultSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchLanguageConsultInputValue(value);

    if (value.trim() !== '') {
      axios.post(`${REST_API_END_POINT}language/language-list`, { search: value })
        .then((res) => {
          let filteredData={}
          if (res.data.status === 1) {
            if(insSearch){
              filteredData = res?.data?.data
           .filter(d => d.status === 1 )
           .filter(d => d.location?.toLowerCase().includes(countryDetailsSearch.name.toLowerCase()));
           // setSearchOptions(filteredData)
           setSearchLanguageConsultOptions(filteredData)

         }
           else{
              filteredData = res?.data?.data
             .filter(d => d.status === 1 )
             .filter(d => d.location?.toLowerCase().includes(countryDetails.name.toLowerCase()));
             // setSearchOptions(filteredData)
             setSearchLanguageConsultOptions(filteredData)
           }
            // setSearchLanguageConsultOptions(res.data?.data);
            setShowNoSearchLanguageConsultOptionsMessage(filteredData?.length === 0);
          }
        })

    } else {
      setSearchLanguageConsultOptions([]);
      setShowNoSearchLanguageConsultOptionsMessage(false);
    }
  };

  const handleLanguageConsultSearchOptionClick = (option) => {
    setSearchLanguageConsultInputValue(option.name);
    setSelectedData(option)
    setSearchLanguageConsultOptions([]);
    setShowNoSearchLanguageConsultOptionsMessage(false);


  };
   const handleChange = (event) => {
    const selectedIndex = event.target.value;
    setSelectedItem(selectedIndex);
    handleItemClickTab(selectedIndex, navItems[selectedIndex].id);
  };

  return (
    <>
      <Navbar setCountryDetails={setCountryDetails} countryDetails={countryDetails}  />

      <section className="relative lg:pt-24 pt-[74px] overflow-hidden"
        //  style={{ background: 'linear-gradient(to right, #ff8c00, #eecf36, #eecf36, #eecf36, #eecf36, #ff8c00)' }}
        style={{ backgroundColor: '#000000' }}
      //  style={{ backgroundColor: '#5057ea' }}
      >
        <div className="container-fluid relative overflow-hidden">
          <div className="lg:pt-26 lg:pb-26 pt-26 pb-366 shadow dark:shadow-gray-800">
            <div className="container">
              <div className="grid grid-cols-1 justify-center text-center">
                <div className="relative">
                  {activeIndex === 'visa' && (
                    <>
                      <p className="text-lg max-w-xl mx-auto mb-5"
                        style={{
                          //  color: '#4eee37',
                          color: '#ffffff',
                          fontWeight: 'bold'
                        }}>99.2% User Satisfaction</p>
                      <div className="relative ">
                        <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Get All Your Visa Questions Answered.</h1>
                      </div>
                    </>
                  )}
                  {activeIndex === 'check-my-visa' && (
                    <>
                      <p className="text-lg max-w-xl mx-auto mb-5" style={{ color: 'white', fontWeight: 'bold' }}>Before You Fly</p>
                      <div className="relative ">
                        <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Check Your Visa Details & Be Sure.</h1>
                      </div>
                    </>
                  )}
                  {activeIndex === 'pr-pathway' && (
                    <>
                      <p className="text-lg max-w-xl mx-auto mb-5" style={{ color: 'white', fontWeight: 'bold' }}>Ensures 99.2% On-Time PR Pathways</p>
                      <div className="relative ">
                        <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Embark On Your Permanent Residency.</h1>
                      </div>
                    </>
                  )}
                  {activeIndex === 'universities' && (
                    <>
                      <p className="text-lg max-w-xl mx-auto mb-5" style={{ color: 'white', fontWeight: 'bold' }}>Empowering Your Education</p>
                      <div className="relative ">
                        <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Your Academic Journey With Visa Search.</h1>
                      </div>
                    </>
                  )}
                  {activeIndex === 'insurance' && (
                    <>
                      {/* <p className="text-lg max-w-xl mx-auto mb-5" style={{ color: 'white', fontWeight: 'bold' }}>Visa Search Insurance Service</p> */}
                      <p className="text-lg max-w-xl mx-auto mb-5" style={{ color: 'white', fontWeight: 'bold' }}>Top Insurance Providers</p>
                      <div className="relative ">
                        {/* <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Travel Insurance</h1> */}
                        <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Find Travel Insurance You Need.</h1>
                      </div>
                    </>
                  )}
                  {activeIndex === 'consultants' && (
                    <>
                      <p className="text-lg max-w-xl mx-auto mb-5" style={{ color: 'white', fontWeight: 'bold' }}>99.2% Satisfied Clients</p>
                      <div className="relative ">
                        {/* <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Consultants You Can Trust</h1> */}
                        <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Find Consultants You Can Trust.</h1>
                      </div>
                    </>
                  )}
                  {activeIndex === 'language-consultants' && (
                    <>
                      <p className="text-lg max-w-xl mx-auto mb-5" style={{ color: 'white', fontWeight: 'bold' }}>Your Gateway to Proficiency</p>
                      <div className="relative ">
                        {/* <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Visa Search Language Center</h1> */}
                        <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Find Your Best Suited Language Centre.</h1>
                      </div>
                    </>
                  )}
                  {activeIndex === 'credits-loan' && (
                    <>
                      {/* <p className="text-lg max-w-xl mx-auto mb-5" style={{ color: 'white', fontWeight: 'bold' }}>Credits/Loan</p> */}
                      <p className="text-lg max-w-xl mx-auto mb-5" style={{ color: 'white', fontWeight: 'bold' }}>Top Credit/Loan Providers</p>
                      <div className="relative ">
                        {/* <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Visa Search Credits/Loan Provider</h1> */}
                        <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Discover The Best Credit/Loan Providers.</h1>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative md:pb-24 pb-16 -mt-16">
        <div className="container z-1">
          <div className="search-container">
            <div className="wrapperr">
              <div className="search_box">
                {activeIndex === 'universities' && (
                  <div className="search">
                    {/* <div onClick={handleRemove} */}
                    <div onClick={handleRemoveUniversityCountry}
                      className="icon-remove"
                    >
                      <CgClose style={{ color: 'black' }} />
                    </div>
                    <input
                      type="text"
                      style={{ color: 'black', fontSize: '15px' }}
                      className="location-input"
                      value={SearchInputValueCountry}
                      onChange={handleSearchInputChangeCountry}
                      placeholder='Choose Country' />
                    <div className="icon">
                      <Globe style={{ color: 'black' }} />
                    </div>
                    {(searchOptionsCountry.length > 0 || showNoSearchOptionsMessagess) && (
                      <ul className="autocomplete-options1">
                        {searchOptionsCountry.length > 0 ? (
                          searchOptionsCountry.map((option, index) => (
                            <li key={index} style={{ fontSize: '15px' }}
                              onClick={() => handleSearchOptionClickCountry(option)}>
                              {option.name}
                            </li>
                          ))
                        ) : (
                          <li>No matching country found</li>
                        )}
                      </ul>
                    )}
                  </div>
                )}
                {activeIndex === 'universities' && (
                  <div className="search">
                    <div onClick={handleRemoveUniversity}
                      className="icon-remove"
                    >
                      <CgClose style={{ color: 'black' }} />
                    </div>
                    <input
                      type="text"
                      style={{ color: 'black', fontSize: '15px' }}
                      className="location-input"
                      value={SearchInputValue}
                      onChange={handleSearchInputChange}
                      placeholder={searchPlaceholder} />
                    <div className="icon">
                      <LuSearch style={{ color: 'black' }} />
                    </div>
                    {(searchOptions.length > 0 || showNoSearchOptionsMessage) && (
                      <ul className="autocomplete-options1">
                        {searchOptions.length > 0 ? (
                          searchOptions.map((option, index) => (
                            <li key={index} style={{ fontSize: '15px' }}
                              onClick={() => handleSearchOptionClick(option)}>
                              {option.name}
                            </li>
                          ))
                        ) : (
                          <li>No matching university found</li>
                        )}
                      </ul>
                    )}
                  </div>
                )}
                {/* {activeIndex === 'universities' && (
                  <>
                    {universitySearch ? (
                      <div className="autocomplete-container search" style={{ marginTop: '0px' }}>
                        <input
                          type="text"
                          className="location-input"
                          style={{ color: 'black', fontSize: '15px' }}
                          // placeholder="What do you want to study ?"
                          placeholder="Your desired course"
                          // value={courseInput}
                          value={SearchInputValueCourse}
                          onChange={handleFindCourse}
                        />


                        <div className="icon" style={{ color: 'black' }}>
                          <LuSchool style={{ color: 'black' }} />
                        </div>

                        {(searchOptionsCourse.length > 0 || showNoSearchOptionsMessages) && (
                          <ul className="autocomplete-options1">
                            {searchOptionsCourse.length > 0 ? (
                              searchOptionsCourse.map((option, index) => (
                                <li key={index} style={{ fontSize: '15px' }}
                                  onClick={() => handleSearchOptionClickCourse(option)}>
                                  {option.name}
                                </li>
                              ))
                            ) : (
                              <li>No matching course found</li>
                            )}
                          </ul>
                        )}
                      </div>


                    ) : (
                      <div className="autocomplete-container search" style={{ marginTop: '0px' }}>
                        <input
                          type="text"
                          className="location-input"
                          style={{ color: 'black', fontSize: '15px' }}
                          // placeholder="What do you want to study ?"
                          placeholder="Your desired course"
                          value={courseInput}
                        // onChange={handleSearchInputChange}
                        />
                        <div className="icon" style={{ color: 'black' }}>
                          <LuSchool style={{ color: 'black' }} />
                        </div>

                        {(courses.length > 0) && (
                          <ul className="autocomplete-options1">
                            {courses.length > 0 ? (
                              courses.map((option, index) => (
                                <li key={index} style={{ fontSize: '15px' }}
                                  onClick={() => handleCourseClick(option)}>
                                  {option.course_name}
                                </li>
                              ))
                            ) : (
                              <li>No matching course found</li>
                            )}
                          </ul>
                        )}
                      </div>


                    )}
                  </>
                )} */}
                 {activeIndex === 'universities' && (
                  <>
                    {universitySearch ? (
                      <div className="autocomplete-container search" style={{ marginTop: '0px' }}>
                        <input
                          type="text"
                          className="location-input"
                          style={{ color: 'black', fontSize: '15px',overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: 'inline-block',
                            maxWidth: '275px', }}
                          // placeholder="What do you want to study ?"
                          placeholder="Your desired course"
                          // value={courseInput}
                          value={SearchInputValueCourse}
                          onChange={handleFindCourse}
                        />


                        <div className="icon" style={{ color: 'black' }}>
                          <LuSchool style={{ color: 'black' }} />
                        </div>

                        {(searchOptionsCourse.length > 0 || showNoSearchOptionsMessages) && (
                          <ul className="autocomplete-options1">
                            {searchOptionsCourse.length > 0 ? (
                              searchOptionsCourse.map((option, index) => (
                                <li key={index} style={{ fontSize: '15px' }}
                                  onClick={() => handleSearchOptionClickCourse(option)}>
                                  {option}
                                </li>
                              ))
                            ) : (
                              <li>No matching course found</li>
                            )}
                          </ul>
                        )}
                      </div>


                    ) : (
                      <div className="autocomplete-container search" style={{ marginTop: '0px' }}>
                        <input
                          type="text"
                          className="location-input"
                          style={{ color: 'black', fontSize: '15px' }}
                          // placeholder="What do you want to study ?"
                          placeholder="Your desired course"
                          value={courseInput}
                        // onChange={handleSearchInputChange}
                        />
                        <div className="icon" style={{ color: 'black' }}>
                          <LuSchool style={{ color: 'black' }} />
                        </div>

                        {(courses.length > 0) && (
                          <ul className="autocomplete-options1">
                            {courses.length > 0 ? (
                              courses.map((option, index) => (
                                <li key={index} style={{ fontSize: '15px' }}
                                  onClick={() => handleCourseClick(option)}>
                                  {option.course_name}
                                </li>
                              ))
                            ) : (
                              <li>No matching course found</li>
                            )}
                          </ul>
                        )}
                      </div>


                    )}
                  </>
                )}

                {activeIndex === 'consultants' && (

                  <>
                    <div className="autocomplete-container search" style={{ marginTop: '0px', zIndex: 99999 }}>
                      <div onClick={handleRemove}
                        className="icon-remove"
                      >
                        <CgClose style={{ color: 'black' }} />
                      </div>
                      <input
                        type="text"
                        className="location-input"
                        style={{ color: 'black', fontSize: '15px' }}
                        placeholder="Choose Country"
                        value={SearchInputValueCountry}
                      onChange={handleSearchInputChangeCountry}
                      />
                      <div className="icon" style={{ color: 'black' }}>
                        <Globe style={{ color: 'black' }} />
                      </div>
  
                      {(searchOptionsCountry.length > 0 || showNoSearchOptionsMessagess) && (
                        <ul className="autocomplete-options1">
                          {searchOptionsCountry.length > 0 ? (
                            searchOptionsCountry.map((option, index) => (
                              <li key={index} style={{ fontSize: '15px' }}
                              onClick={() => handleSearchOptionClickCountry(option)}>
                              {option.name}
                            </li>
                            ))
                          ) : (
                            <li>No matching found</li>
                          )}
                        </ul>
                      )}
                    </div>
                    <div className="autocomplete-container search" style={{ marginTop: '0px', zIndex: 99999 }}>
                      <div onClick={handleRemoveConsultant}
                        className="icon-remove"
                      >
                        <CgClose style={{ color: 'black' }} />
                      </div>
                      <input
                        type="text"
                        className="location-input"
                        style={{ color: 'black', fontSize: '15px' }}
                        placeholder="Search migration consultant..."
                        // placeholder="Choose services"
                        value={SearchInputValue}
                        onChange={handleSearchInputChange}
                      />
                      <div className="icon" style={{ color: 'black' }}>
                        <Globe style={{ color: 'black' }} />
                      </div>
  
                      {(searchOptions.length > 0 || showNoSearchOptionsMessage) && (
                        <ul className="autocomplete-options1">
                          {searchOptions.length > 0 ? (
                            searchOptions.map((option, index) => (
                              <li key={index} style={{ fontSize: '15px' }}
                                onClick={() => handleServiceSearchOptionClick(option)}>
                                {option.name}
                              </li>
                            ))
                          ) : (
                            <li>No matching found</li>
                          )}
                        </ul>
                      )}
                    </div>
                    <div style={{marginTop: '7px',marginRight:'7px'}}>
  <button
    className={`inline-flex justify-center  font-semibold px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none ${language[activIndexConsultantService].color}`}
    style={{
      borderRadius: '10px',
      color: visaServices[activIndexConsultantService].color,
      width:'120px',
      
    }}
    // onClick={() => setDropdownOpen(!dropdownOpen)}
    onClick={() => {
      setDropdownOpenservice(!dropdownOpenservice);
      if(dropdownOpen){

        setDropdownOpen(!dropdownOpen);
      }
    }}
  >

    <div style={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      maxWidth: '110px',
      whiteSpace:'nowrap'}}>
      {visaServices[activIndexConsultantService].label}
  
  
    </div>
<div style={{marginLeft:'8px',marginTop:'4px'}}>
  
      <svg
            className={`w-4 h-4 ml- transition-transform transform ${
              dropdownOpenservice ? "rotate-180" : "rotate-0"
            }`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
          
</div>
  </button>

  {dropdownOpenservice && (
    <ul className="absolute z-10 mt-2  bg-white border border-gray-300 rounded-lg shadow-lg" 
    // style={{width:'fit-content'}}
    style={{
      width: 'fit-content',
      maxHeight: '200px', // Adjust as needed
      overflowY: 'auto',
      overflowX: 'hidden'
    }}
    >
      {visaServices.map((item, index) => (
       <li
       key={index}
       className="cursor-pointer px-4 py-2 text-left"
       style={{
        //  color: index === activIndexConsultant ? item.color : "black",
         color: index === activIndexConsultantService ? item.color : "black",
         backgroundColor: "white",
         transition: "background-color 0.3s",
       }}
       onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "grey")}
       onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "white")}
       onClick={() => {
        handleItemClickTabConsultantService(index, item.id);
        setDropdownOpenservice(false);
       }}
     >
       {item.label}
     </li>
     
      ))}
    </ul>
  )}
</div>
                    <div style={{marginTop: '7px',marginRight:'7px'}}>
  <button
    className={`inline-flex justify-center  font-semibold px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none ${language[activIndexConsultant].color}`}
    style={{
      borderRadius: '10px',
      color: language[activIndexConsultant].color,
      width:'120px'
    }}
    // onClick={() => setDropdownOpen(!dropdownOpen)}
    onClick={() => {
      setDropdownOpen(!dropdownOpen);
      if(dropdownOpenservice){

        setDropdownOpenservice(!dropdownOpenservice);
      }
    }}
  >
   <div style={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      maxWidth: '110px',
      whiteSpace:'nowrap'}}>
      {language[activIndexConsultant].label}
      
   </div>
<div style={{marginLeft:'8px',marginTop:'4px'}}>
  
      <svg
            className={`w-4 h-4 ml- transition-transform transform ${
              dropdownOpen ? "rotate-180" : "rotate-0"
            }`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
          
</div>
  </button>

  {dropdownOpen && (
    <ul className="absolute z-10 mt-2  bg-white border border-gray-300 rounded-lg shadow-lg"
    //  style={{width:'fit-content'}}
    style={{
      width: 'fit-content',
      maxHeight: '200px', // Adjust as needed
      overflowY: 'auto',
      overflowX: 'hidden'
    }}
     >
      {language.map((item, index) => (
       <li
       key={index}
       className="cursor-pointer px-4 py-2 text-left"
       style={{
         color: index === activIndexConsultant ? item.color : "black",
         backgroundColor: "white",
         transition: "background-color 0.3s",
       }}
       onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "grey")}
       onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "white")}
       onClick={() => {
         handleItemClickTabConsultant(index, item.id);
         setDropdownOpen(false);
       }}
     >
       {item.label}
     </li>
     
      ))}
    </ul>
  )}
</div>
                  </>

                )}
                {activeIndex === 'visa' && (
                  <div className="autocomplete-container search" style={{ marginTop: '0px' }}>
                    <div onClick={handleRemove}
                      className="icon-remove"
                    >
                      <CgClose style={{ color: 'black' }} />
                    </div>
                    <input
                      type="text"
                      className="location-input"
                      style={{ color: 'black', fontSize: '15px' }}
                      placeholder="Choose Country ?"
                      value={SearchInputValue}
                      onChange={handleSearchInputChange}
                    />
                    <div className="icon" style={{ color: 'black' }}>
                      <Globe style={{ color: 'black' }} />
                    </div>

                    {(searchOptions.length > 0 || showNoSearchOptionsMessage) && (
                      <ul className="autocomplete-options1">
                        {searchOptions.length > 0 ? (
                          searchOptions.map((option, index) => (
                            <li key={index} style={{ fontSize: '15px' }}
                              onClick={() => handleSearchOptionClick(option)}>
                              {option.name}
                            </li>
                          ))
                        ) : (
                          <li>No matching  found</li>
                        )}
                      </ul>
                    )}
                  </div>
                )}
                {activeIndex === 'check-my-visa' && (
                  <div className="autocomplete-container search" style={{ marginTop: '0px' }}>
                    <div onClick={handleRemove}
                      className="icon-remove"
                    >
                      <CgClose style={{ color: 'black' }} />
                    </div>
                    <input
                      type="text"
                      className="location-input"
                      style={{ color: 'black', fontSize: '15px' }}
                      placeholder="Choose Country to Check Your Visa"
                      value={SearchInputValue}
                      onChange={handleSearchInputChange}
                    />
                    <div className="icon" style={{ color: 'black' }}>
                      <Globe style={{ color: 'black' }} />
                    </div>

                    {(searchOptions.length > 0 || showNoSearchOptionsMessage) && (
                      <ul className="autocomplete-options1">
                        {searchOptions.length > 0 ? (
                          searchOptions.map((option, index) => (
                            <li key={index} style={{ fontSize: '15px' }}
                              onClick={() => handleSearchOptionClick(option)}>
                              {option.name}
                            </li>
                          ))
                        ) : (
                          <li>No matching found</li>
                        )}
                      </ul>
                    )}
                  </div>
                )}
                {activeIndex === 'pr-pathway' && (
                  <div className="autocomplete-container search" style={{ marginTop: '0px' }}>
                    <div onClick={handleRemove}
                      className="icon-remove"
                    >
                      <CgClose style={{ color: 'black' }} />
                    </div>
                    <input
                      type="text"
                      className="location-input"
                      style={{ color: 'black', fontSize: '15px' }}
                      placeholder="Choose country for your path to permanent residency"
                      value={SearchInputValue}
                      onChange={handleSearchInputChange}
                    />
                    <div className="icon" style={{ color: 'black' }}>
                      <LuSchool style={{ color: 'black' }} />
                    </div>

                    {(searchOptions.length > 0 || showNoSearchOptionsMessage) && (
                      <ul className="autocomplete-options1">
                        {searchOptions.length > 0 ? (
                          searchOptions.map((option, index) => (
                            <li key={index} style={{ fontSize: '15px' }}
                              onClick={() => handleSearchOptionClick(option)}>
                              {option.name}
                            </li>
                          ))
                        ) : (
                          <li>No matching found</li>
                        )}
                      </ul>
                    )}
                  </div>
                )}
                {activeIndex === 'insurance' && (
                  <>
                  <div className="search" style={{ marginTop: '0px' }}>
                    <div onClick={handleRemove}
                      className="icon-remove"
                    >
                      <CgClose style={{ color: 'black' }} />
                    </div>
                    <input
                      type="text"
                      className="location-input"
                      style={{ color: 'black', fontSize: '15px' }}
                      placeholder="Which Place Are Looking For Travelling to..."
                      value={SearchInputValue}
                      onChange={handleSearchInputChange}
                    />
                    <div className="icon" style={{ color: 'black' }}>
                      <LuSchool style={{ color: 'black' }} />
                    </div>

                    {(searchOptions.length > 0 || showNoSearchOptionsMessage) && (
                      <ul className="autocomplete-options1">
                        {searchOptions.length > 0 ? (
                          searchOptions.map((option, index) => (
                            <li key={index} style={{ fontSize: '15px' }}
                              onClick={() => handleSearchOptionClick(option)}>
                              {option.name}
                            </li>
                          ))
                        ) : (
                          <li>No matching country found</li>
                        )}
                      </ul>
                    )}

                  </div>
                  {/* <div className="autocomplete-container search" style={{ marginTop: '-14px' }}>
      <Select
        value={selectedItem}
        onChange={handleChange}
        style={{ marginLeft: '62px', color: navItems[selectedItem].color }}
        className="nav-dropdown"
      >
        {navItems.map((item, index) => (
          <menuitem key={index} value={index} style={{ color: item.color }}>
            {item.text}
          </menuitem>
        ))}
      </Select>
    </div> */}
  {/* <div className="nav-dropdown"> */}
    {/* <select
      className=" font-semibold"
      value={activIndex}
      onChange={(e) => handleItemClickTab(parseInt(e.target.value), navItems[parseInt(e.target.value)].id)}
      style={{
        borderRadius:'10px',
        color: navItems[activIndex].color,
      }}
    >
      {navItems.map((item, index) => (
        <option
          key={index}
          value={index}
          style={{
            color: index === activIndex ? item.color : "black",
          }}
        >
          {item.text}
        </option>
      ))}
    </select> */}
  {/* </div> */}
  {/* <div className="relative"> */}
  <div style={{marginTop: '7px',marginRight:'7px'}}>
  <button
    className={`inline-flex justify-center  font-semibold px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none ${navItems[activIndex].color}`}
    style={{
      borderRadius: '10px',
      color: navItems[activIndex].color,
      width:'90px'
    }}
    onClick={() => setDropdownOpen(!dropdownOpen)}
  >
    {navItems[activIndex].text}
<div style={{marginLeft:'8px',marginTop:'4px'}}>
  
      <svg
            className={`w-4 h-4 ml- transition-transform transform ${
              dropdownOpen ? "rotate-180" : "rotate-0"
            }`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
          
</div>
  </button>

  {dropdownOpen && (
    <ul className="absolute z-10 mt-2  bg-white border border-gray-300 rounded-lg shadow-lg"
    //  style={{width:'fit-content'}}
    style={{
      width: 'fit-content',
      maxHeight: '200px', // Adjust as needed
      overflowY: 'auto',
      overflowX: 'hidden'
    }}
     >
      {navItems.map((item, index) => (
       <li
       key={index}
       className="cursor-pointer px-4 py-2 text-left"
       style={{
         color: index === activIndex ? item.color : "black",
         backgroundColor: "white",
         transition: "background-color 0.3s",
       }}
       onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "grey")}
       onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "white")}
       onClick={() => {
         handleItemClickTab(index, item.id);
         setDropdownOpen(false);
       }}
     >
       {item.text}
     </li>
     
      ))}
    </ul>
  )}
</div>


                   
                      {/* <div className="autocomplete-container search" style={{ marginTop: '-14px' }}>
   <nav className="nav" style={{marginLeft:'62px'}}>
                      {navItems.map((item, index) => (
                        <a
                          key={index}
                          className={`nav-item-one font-semibold ${index === activIndex ? "is-active" : ""
                            }`}
                          active-color={item.color}
                          onClick={() => handleItemClickTab(index, item.id)}
                          style={{
                            color:
                              index === activIndex ? item.color : "#83818c",
                          }}
                        >
                          {item.text}
                        </a>
                      ))}
                      <span
                        className="nav-indicator"
                        style={{
                          width: `${100 / navItems.length}%`,
                          left: `${activIndex * (100 / navItems.length) +
                            (activIndex == 0 ? 3 : activIndex == 2 ? -4 : 0)
                            }%`,
                          backgroundColor: navItems[activIndex].color,
                        }}
                      ></span>
                    </nav>
                      </div> */}
                      </>
                )}
                {activeIndex === 'credits-loan' && (
                  <>
                  <div className="autocomplete-container search" style={{ marginTop: '0px' }}>
                    <div onClick={handleRemove}
                      className="icon-remove"
                    >
                      <CgClose style={{ color: 'black' }} />
                    </div>
                    <input
                      type="text"
                      className="location-input"
                      style={{ color: 'black', fontSize: '15px' }}
                      // placeholder="Discover Credits/Loans For You..."
                      placeholder="Choose Country"
                      value={SearchInputValueCountry}
                      onChange={handleSearchInputChangeCountry}
                    />
                    {/* <div className="icon" style={{ color: 'black' }}>
                      <LuSchool style={{ color: 'black' }} />
                    </div> */}
                      <div className="icon" style={{ color: 'black' }}>
                      <Globe style={{ color: 'black' }} />
                    </div>
                    {(searchOptionsCountry.length > 0 || showNoSearchOptionsMessagess) && (
                      <ul className="autocomplete-options1">
                        {searchOptionsCountry.length > 0 ? (
                          searchOptionsCountry.map((option, index) => (
                            <li key={index} style={{ fontSize: '15px' }}
                              onClick={() => handleSearchOptionClickCountry(option)}>
                              {option.name}
                            </li>
                          ))
                        ) : (
                          <li>No matching found</li>
                        )}
                      </ul>
                    )}

                  </div>
                  {/* <div className="autocomplete-container search" style={{ marginTop: '0px' }}>
                    <div onClick={handleRemove}
                      className="icon-remove"
                    >
                      <CgClose style={{ color: 'black' }} />
                    </div>
                    <input
                      type="text"
                      className="location-input"
                      style={{ color: 'black', fontSize: '15px' }}
                      placeholder="Discover Credits/Loans For You..."
                   
                      value={SearchInputValue}
                      onChange={handleSearchInputChange}
                    />
                    <div className="icon" style={{ color: 'black' }}>
                      <LuSchool style={{ color: 'black' }} />
                    </div>
                 
                    {(searchOptions.length > 0 || showNoSearchOptionsMessage) && (
                      <ul className="autocomplete-options1">
                        {searchOptions.length > 0 ? (
                          searchOptions.map((option, index) => (
                            <li key={index} style={{ fontSize: '15px' }}
                              onClick={() => handleSearchOptionClick(option)}>
                              {option.loan_name} ({option.name})
                            </li>
                          ))
                        ) : (
                          <li>No matching found</li>
                        )}
                      </ul>
                    )}

                  </div> */}

                                      <div style={{ marginTop: '7px', marginRight: '7px' }}>
  <button
    className={`inline-flex justify-center font-semibold px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none ${creditType[activIndexLanguage].color}`}
    style={{
      borderRadius: '10px',
      color: creditType[activIndexLanguage].color,
      width: '145px'
    }}
    onClick={() => {
      setDropdownOpenLang(!dropdownOpenLang);
    }}
  >
    <div style={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      maxWidth: '110px',
      whiteSpace: 'nowrap'
    }}>
      {console.log("languageData[activIndexLanguage].label",creditType)}
      {console.log("languageData[activIndexLanguage].label",activIndexLanguage)}
      
      {creditType[activIndexLanguage].label}
    </div>
    <div style={{ marginLeft: '8px', marginTop: '4px' }}>
      <svg
        className={`w-4 h-4 ml- transition-transform transform ${
          dropdownOpenLang ? "rotate-180" : "rotate-0"
        }`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </div>
  </button>

  {dropdownOpenLang && (
    <ul
      className="absolute z-10 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg"
      style={{
        width: 'fit-content',
        maxHeight: '200px', // Adjust as needed
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
    >
      {creditType.map((item, index) => (
        <li
          key={index}
          className="cursor-pointer px-4 py-2 text-left"
          style={{
            color: index === activIndexLanguage ? item.color : "black",
            backgroundColor: "white",
            transition: "background-color 0.3s",
          }}
          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "grey")}
          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "white")}
          onClick={() => {
            handleItemClickTabLanguages(index, item.id);
            setDropdownOpenLang(false);
          }}
        >
          {item.label}
        </li>
      ))}
    </ul>
  )}
</div>
                  </>
                )}
                {/* <div className="search">
                  <input
                    type="text"
                    style={{color:'black',fontSize:'15px'}}
                    className="location-input"
                    ref={inputRef}
                    placeholder={searchPlaceholder} />
                    <div className="icon">
                      <LuSearch style={{color:'black'}} />
                    </div>
                  </div> */}
                {/* {activeIndex === 'consultants' && (
                      <div className="autocomplete-container search" style={{marginTop:'0px'}}>
                          <input
                              type="text"
                              className="location-input"
                              style={{ color: 'black', fontSize:'15px'}}
                              placeholder="Select language"
                              value={SearchInputLanguageValue}
                              onChange={handleLanguageSearchInputChange}
                          />
                          <div className="icon" style={{ color: 'black' }}>
                              <FaLanguage style={{color:'black'}} />
                          </div>

                          {(searchLanguageOptions.length > 0 || showNoSearchLanguageOptionsMessage) && (
                              <ul className="autocomplete-options1">
                                  {searchLanguageOptions.length > 0 ? (
                                      searchLanguageOptions.map((option, index) => (
                                          <li key={index} style={{fontSize:'15px'}} onClick={() => handleLanguageSearchOptionClick(option)}>
                                              {option}
                                          </li>
                                      ))
                                  ) : (
                                      <li>No matching course found</li>
                                  )}
                              </ul>
                          )}
                      </div>
                  )} */}
                {activeIndex === 'language-consultants' && (

                  <>
                  <div className="autocomplete-container search" style={{ marginTop: '0px', zIndex: 99999 }}>
                      <div onClick={handleRemove}
                        className="icon-remove"
                      >
                        <CgClose style={{ color: 'black' }} />
                      </div>
                      <input
                        type="text"
                        className="location-input"
                        style={{ color: 'black', fontSize: '15px' }}
                        placeholder="Choose Country"
                        value={SearchInputValueCountry}
                      onChange={handleSearchInputChangeCountry}
                      />
                      <div className="icon" style={{ color: 'black' }}>
                        <Globe style={{ color: 'black' }} />
                      </div>
  
                      {(searchOptionsCountry.length > 0 || showNoSearchOptionsMessagess) && (
                        <ul className="autocomplete-options1">
                          {searchOptionsCountry.length > 0 ? (
                            searchOptionsCountry.map((option, index) => (
                              <li key={index} style={{ fontSize: '15px' }}
                              onClick={() => handleSearchOptionClickCountry(option)}>
                              {option.name}
                            </li>
                            ))
                          ) : (
                            <li>No matching found</li>
                          )}
                        </ul>
                      )}
                    </div>
                    <div className="autocomplete-container search" style={{ marginTop: '0px' }}>
                      <div onClick={handleRemoveConsultant}
                        className="icon-remove"
                      >
                        <CgClose style={{ color: 'black' }} />
                      </div>
                      <input
                        type="text"
                        className="location-input"
                        style={{ color: 'black', fontSize: '15px' }}
                        placeholder="Search Language Consultant"
                        value={SearchInputLanguageConsultValue}
                        onChange={handleLanguageConsultSearchInputChange}
                      />
                      <div className="icon" style={{ color: 'black' }}>
                        <FaLanguage style={{ color: 'black' }} />
                      </div>
  
                      {(searchLanguageConsultOptions.length > 0 || showNoSearchLanguageConsultOptionsMessage) && (
                        <ul className="autocomplete-options1">
                          {searchLanguageConsultOptions.length > 0 ? (
                            searchLanguageConsultOptions.map((option, index) => (
                              <li key={index} style={{ fontSize: '15px' }} onClick={() => handleLanguageConsultSearchOptionClick(option)}>
                                {option.name}
                              </li>
                            ))
                          ) : (
                            <li>No matching found</li>
                          )}
                        </ul>
                      )}
                    </div>
                    <div style={{ marginTop: '7px', marginRight: '7px' }}>
  <button
    className={`inline-flex justify-center font-semibold px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none ${languageData[activIndexLanguage].color}`}
    style={{
      borderRadius: '10px',
      color: languageData[activIndexLanguage].color,
      width: '120px'
    }}
    onClick={() => {
      setDropdownOpenLang(!dropdownOpenLang);
    }}
  >
    <div style={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      maxWidth: '110px',
      whiteSpace: 'nowrap'
    }}>
      {console.log("languageData[activIndexLanguage].label",languageData)}
      {console.log("languageData[activIndexLanguage].label",activIndexLanguage)}
      
      {languageData[activIndexLanguage].label}
    </div>
    <div style={{ marginLeft: '8px', marginTop: '4px' }}>
      <svg
        className={`w-4 h-4 ml- transition-transform transform ${
          dropdownOpenLang ? "rotate-180" : "rotate-0"
        }`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </div>
  </button>

  {dropdownOpenLang && (
    <ul
      className="absolute z-10 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg"
      style={{
        width: 'fit-content',
        maxHeight: '200px', // Adjust as needed
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
    >
      {languageData.map((item, index) => (
        <li
          key={index}
          className="cursor-pointer px-4 py-2 text-left"
          style={{
            color: index === activIndexLanguage ? item.color : "black",
            backgroundColor: "white",
            transition: "background-color 0.3s",
          }}
          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "grey")}
          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "white")}
          onClick={() => {
            handleItemClickTabLanguages(index, item.id);
            setDropdownOpenLang(false);
          }}
        >
          {item.label}
        </li>
      ))}
    </ul>
  )}
</div>

                  </>
                )}
              </div>
            </div>
          </div>
        </div>



        <div className='wrapper3' style={{ marginTop: '0px' }}>
          <ul className="mt-10 justify-center text-center p-2 bg-white dark:bg-slate-900 shadow dark:shadow-gray-8001" id="myTab" data-tabs-toggle="#StarterContent" role="tablist" style={{ borderRadius: '20px' }}>
            <li role="presentation" className="md:inline-block block md:w-fit w-full">
              <Link
                to={`/visa`}
                className={`px-6 py-2 font-semibold roundedd-md w-full transition-all duration-500 ease-in-out ${activeIndex === 'visa' ? 'text-white bg-violet-6001' : ''}`}
                type="button"
                onClick={() => setIndex('visa')}
                style={{ color: 'black' }}
              >
                Visa
              </Link>
            </li>
            <li role="presentation" className="md:inline-block block md:w-fit w-full">
              <Link
                to={`/check-my-visa`}
                className={`px-6 py-2 font-semibold roundedd-md w-full transition-all duration-500 ease-in-out ${activeIndex === 'check-my-visa' ? 'text-white bg-violet-6001' : ''}`}
                type="button"
                onClick={() => setIndex('check-my-visa')}
                style={{ color: 'black' }}
              >
                Check My Visa
              </Link>
            </li>
            <li role="presentation" className="md:inline-block block md:w-fit w-full">
              <Link
                to={`/pr-pathway`}
                className={`px-6 py-2 font-semibold roundedd-md w-full transition-all duration-500 ease-in-out ${activeIndex === 'pr-pathway' ? 'text-white bg-violet-6001' : ''}`}
                type="button"
                onClick={() => setIndex('pr-pathway')}
                style={{ color: 'black' }}
              >
                {/* Insurance  */}
                PR Pathways
              </Link>
            </li>
            <li role="presentation" className="md:inline-block block md:w-fit w-full">
              <Link
                to={`/insurance`}
                className={`px-6 py-2 font-semibold roundedd-md w-full transition-all duration-500 ease-in-out ${activeIndex === 'insurance' ? 'text-white bg-violet-6001' : ''}`}
                type="button"
                onClick={() => {
                  setIndex('insurance')
                  setSearchInputValue('')
                  setSelectedData({})
                  setShowNoSearchOptionsMessage(false)
                  setSearchOptions([])
                }}
                style={{ color: 'black' }}
              >
                Insurance

              </Link>
            </li>
            <li role="presentation" className="md:inline-block block md:w-fit w-full">
              <Link
                to={`/universities`}
                className={`px-6 py-2 font-semibold roundedd-md w-full transition-all duration-500 ease-in-out ${activeIndex === 'universities' ? 'text-white bg-violet-6001' : ''}`}
                type="button"
                onClick={() => setIndex('universities')}
                style={{ color: 'black' }}

              >
                Universities
              </Link>
            </li>
            <li role="presentation" className="md:inline-block block md:w-fit w-full">
              <Link
                to={`/consultants`}
                className={`px-6 py-2 font-semibold roundedd-md w-full transition-all duration-500 ease-in-out ${activeIndex === 'consultants' ? 'text-white bg-violet-6001' : ''}`}
                type="button"
                onClick={() => setIndex('consultants')}
                style={{ color: 'black' }}

              >
                Consultants
              </Link>
            </li>
            <li role="presentation" className="md:inline-block block md:w-fit w-full">
              <Link
                to={`/language-consultants`}
                className={`px-6 py-2 font-semibold roundedd-md w-full transition-all duration-500 ease-in-out ${activeIndex === 'language-consultants' ? 'text-white bg-violet-6001' : ''}`}
                type="button"
                onClick={() => {
                  setIndex('language-consultants')
                }}
                style={{ color: 'black' }}

              >
                Language
              </Link>
            </li>
            <li role="presentation" className="md:inline-block block md:w-fit w-full">
              <Link
                to={`/credits-loan`}
                className={`px-6 py-2 font-semibold roundedd-md w-full transition-all duration-500 ease-in-out ${activeIndex === 'credits-loan' ? 'text-white bg-violet-6001' : ''}`}
                type="button"
                onClick={() => setIndex('credits-loan')}
                style={{ color: 'black' }}

              >
                Credits/Loan
              </Link>
            </li>
          </ul>
        </div>
      </section>


      <section id='listing' style={{ marginTop: '-100px' }}>

        {activeIndex === 'visa' &&  <VisaListing typeId={typeId}
          setTypeId={setTypeId} setVisaSelect={setVisaSelect}
          activeIndex={activeIndex} navItems={navItems}
          VISA_REQUIREMENT={VISA_REQUIREMENT} refresh={refresh}
          countrySelected={selectedData}
          countryPrefer={countryDetails}
          setSearchInputValue={setSearchInputValue}
          
        />}
        {activeIndex === 'check-my-visa' && <CheckMyVisa refresh={refresh}
         countryPrefer={countryDetails}
          countrySelected={selectedData}
           activeIndex={activeIndex} />}
        {activeIndex === 'pr-pathway' && <PrPathwayListing refresh={refresh} activeIndex={activeIndex} prSelected={selectedData} />}
        {activeIndex === 'insurance' && <InsuranceListing setInsTypeId={setInsTypeId} insTypeId={insTypeId} countryPrefer={insSearch?countryDetailsSearch:countryDetails} refresh={refresh} activeIndex={activeIndex} insuranceSelected={selectedData} activIndex={activIndex} setActivIndex={setActivIndex} />}
        {activeIndex === 'universities' && <UniversityListing countryPrefer={insSearch?countryDetailsSearch:countryDetails}  refresh={refresh} activeIndex={activeIndex} universitySelected={selectedData} courseData={searchOptionsCourses}/>}
        {activeIndex === 'consultants' && <ConsultantsListing setConsLangId={setConsLangId} consLangId={consLangId} consSerId={consSerId} countryPrefer={insSearch?countryDetailsSearch:countryDetails} refresh={refresh} activeIndex={activeIndex} consultSelect={selectedData} activIndexConsultant={activIndexConsultant} activIndexConsultantService={activIndexConsultantService}/>}
        {activeIndex === 'language-consultants' && <LanguagesListing countryPrefer={insSearch?countryDetailsSearch:countryDetails} languageSelect={selectedData} refresh={refresh} activeIndex={activeIndex} activIndexLanguage={activIndexLanguage} />}
        {activeIndex === 'credits-loan' && <CreditsLoanListing countryPrefer={insSearch?countryDetailsSearch:countryDetails} refresh={refresh} activeIndex={activeIndex} loanselect={selectedData} activIndexLoan={activIndexLanguage}/>}
      </section>

      <Footer />

    </>
  )
}
