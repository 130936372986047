import React, { useEffect, useState } from 'react';
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import Select from 'react-select';
import Switcher from '../../../components/switcher';
import { Icon } from '@iconify/react';
import airplaneTakeoffDuotone from '@iconify/icons-ph/airplane-takeoff-duotone';
import { Link } from 'react-router-dom';
import star from '../../../assets/images/rating-star.png';
import Tick from '../../../assets/images/tick1.svg';
import Tick1 from '../../../assets/images/travel-tick-icon.svg';
import World from '../../../assets/images/world.png';
import { loan } from '../../../data/data';
import { LuClock, LuCalendarDays, RiDragDropLine, LuSearch, AiOutlineAppstore, AiOutlineStar, MdKeyboardArrowLeft, MdKeyboardArrowRight, PiBrowsersLight, MdOutlineSportsVolleyball, AiOutlinePieChart, LuMusic4, TbCameraPlus, PiRocketLaunchBold, FaImages, ImImages, BiImage, BiImages, AiOutlineInsurance, AiFillInsurance, BiMoney, BsCash, TbCash, TbCashBanknote, RiArrowDropDownLine } from "../../../assets/icons/vander";
import axios from 'axios';
import { REST_API_END_POINT } from '../../../constants/DefaultValue';
import Error from '../../special/error';
import { DNA } from 'react-loader-spinner';


const serviceFilters = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
  };

export default function CreditsLoanListing({ activeIndex, loanselect, refresh,countryPrefer,activIndexLoan }) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true);
    // const fetchAllBanks = async () => {
    //     try {
    //         const response = await axios.post(`${REST_API_END_POINT}bank/get-all-banks`, {});
    //         if (response.data.status === 1) {
    //             const filteredData = response.data.data.filter(d => d.status === 1);

    //             const promises = filteredData.map(async eachRow => {
    //                 try {
    //                     const res = await axios.post(`${REST_API_END_POINT}bank-loan/get-all-loans`, { bankId: eachRow?.id, getloans: true ,from_user:true});
    //                     return res.data.data.filter(d => d.loan_status === 1);
    //                 } catch (err) {
    //                     console.error(`Error fetching loans for bank ID ${eachRow?.id}:`, err);
    //                     return [];
    //                 }
    //             });

    //             const loans = await Promise.all(promises);
    //             setData(loans.flat().filter(d => d.loan_status === 1));
    //         }
    //         else{
    //             setData([])
    //         }
    //     } catch (err) {
    //         console.error('Error fetching bank data:', err);
    //     }
    // };

    const fetchAllBanks = async () => {
        try {
            const response = await axios.post(`${REST_API_END_POINT}bank/get-all-banks`, {from_user:true});
            if (response.data.status === 1) {
                let filteredData = response.data.data
                .filter(d => d.status === 1)
                .filter(d => d.location.toLowerCase().includes(countryPrefer.name.toLowerCase()));
                if (activIndexLoan in serviceFilters) {
                    console.log("serviceFilters[activIndexLoan]",serviceFilters[activIndexLoan]);
                    
                    const languageId = serviceFilters[activIndexLoan];
                    filteredData = filteredData.filter((d) =>
                      d.credit_type?.split(',').includes(languageId)
                    );
                    console.log(`Filtered by Service ID ${languageId}:`, filteredData);
                  }
                setData(filteredData);
            }
            else{
                setData([])
            }
        } catch (err) {
            console.error('Error fetching bank data:', err);
        }
    };
    useEffect(() => {
        setLoading(true);
        const timer = setTimeout(() => {
            setLoading(false);
          }, 5000);
        fetchAllBanks()
        return () => clearTimeout(timer);
    }, [refresh,countryPrefer,activIndexLoan])

    useEffect(() => {
        setData([loanselect])
    }, [loanselect])

  const handleClickCard=(link)=>{
    window.open(link, '_blank')
  }   

    return (
        <>

            {/*<section className="relative mb-6" style={{ marginTop: '45px' }}>
            {data?.length > 0 ?
                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[30px]" style={{ padding: '20px' }}>
                    {
                        data?.map((ele, index) => (
                            <Link key={index} to={ele.detail_added === 1 && `/credits-loan-details/${ele.loan_id}/${activeIndex}`}
                                style={{
                                    display: 'flex', alignItems: 'stretch', justifyContent: 'stretch',
                                    alignContent: 'stretch'
                                }}>
                                <div key={index} className="group relative overflow-hidden shadow dark:shadow-gray-700 hover:shadow-md transition-all duration-100 " style={{ borderRadius: '20px', }}>
                                    <img style={{ maxWidth: '600px', height: '300px', objectFit: 'cover' }} src={ele.cover_image} alt="" />
                                    <div className="relative p-6">
                                        <div className="bagde-flag-wrap1">
                                            <img
                                                style={{
                                                    position: 'absolute',
                                                    top: '0',
                                                    right: '0',
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    zIndex: '2',
                                                }}
                                                src={ele.logo}
                                                alt="Logo"
                                            />
                                        </div>
                                        <div>

                                        <div className="color-price" style={{ marginTop: '10px', marginLeft: '-10px', position: 'relative', display: 'flex', justifyContent: 'flex-start' }}>
                          <div className="color-option">
                            <div className="flex items-center" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Link to={ele.detail_added === 1 && `/credits-loan-details/${ele.loan_id}/${activeIndex}`}
                                                     data-ripple-light="true"
                                                     data-tooltip-target="tooltip"   className="color" style={{ fontWeight: 'bold', fontSize: '21px', color: '#333333', maxWidth: 220, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: '5px', marginBottom: '6px' }}>{ele.name}
                                                     </Link>
                                                     <div
                                  data-tooltip="tooltip"
                                  class="tooltip"
                                >
                                  {ele.name}
                                </div>
                                                </div>
                                                </div>
                                                </div>
                                                { ele.verified !=1 ? (<div className="price" style={{ marginLeft: '35px',display:'flex',justifyContent:'flex-end' }}>
                                                  <div className="color-option">
                                                    <div className="flex items-center justify-center" style={{ marginTop: '-3px', textAlign: 'right' }}>
                                                      <Link style={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'right', display: 'flex', justifyContent: 'center', alignContent: 'center', maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} class="color">
                                                        Coming Soon
                                                      </Link>
                                                    
                                                    </div>
                                                  </div>
                                                </div>) : ''}
                                            </div>

                                            <div className="color-price" style={{
                                                marginTop: '10px',
                                                marginLeft: '-10px', position: 'relative', display: 'flex', justifyContent: 'flex-start'
                                            }}>
                                                <div className="color-option">
                                                    <div className="flex items-center" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <div style={{
                                                            fontSize: '26px', color: '#000000',
                                                            marginRight: '10px'
                                                        }}>
                                                            <span class="ion--location-outline"></span>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <Link to={ele.detail_added === 1 && `/credits-loan-details/${ele.loan_id}/${activeIndex}`}
                                                            data-ripple-light="true"
                                                            data-tooltip-target="tooltip"   className="color" style={{ fontSize: '15px', color: '#000', maxWidth: 180, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                               {ele.location}
                                                               <span className="underline"></span>
                                                               <div
                                  data-tooltip="tooltip"
                                  class="tooltip"
                                >
                                  {ele.location}
                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                           
                                            
                        </div>

                                            <div className="color-price" style={{ marginTop: '30px', marginLeft: '-10px' }}>
                                                <div className="flex items-center">
                                                    <div style={{ fontSize: '30px', color: '#000000', marginRight: '10px' }}>
                                                        <span style={{ marginLeft: '-3px' }} class="game-icons--money-stack"></span>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <Link to={ele.detail_added === 1 && `/credits-loan-details/${ele.loan_id}/${activeIndex}`}
                                                            className="color" style={{ fontSize: '18px', color: '#858585', marginBottom: '4px', fontWeight: 'bold' }}>
                                                            Funds disbursed</Link>
                                                        <Link to={ele.detail_added === 1 && `/credits-loan-details/${ele.loan_id}/${activeIndex}`}
                                                            className="color" style={{ fontSize: '16px', color: '#000000', fontWeight: 'bold', maxWidth: 170, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                            {ele.loan_name}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))
                    }
                </div>
                : <Error />}

            </section>*/}
             {/* the above is the real one but for the initial Phase we create like this*/}
             {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh',
          }}
        >
        <DNA
        visible={true}
        height="140"
        width="140"     
        ariaLabel="dna-loading"
        wrapperStyle={{}}
         wrapperClass="dna-wrapper"
           />
        </div>
      ) : (
              <section className="relative mb-6" style={{ marginTop: '45px' }}>
            {data?.length > 0 ?
                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[30px]" style={{ padding: '20px' }}>
                    {
                        data?.map((ele, index) => (
                            <Link key={index} 
                            onClick={()=>{ ele.verified===1 && handleClickCard(ele.bank_link)}}
                            // to={ele.detail_added === 1 && `/credits-loan-details/${ele.loan_id}/${activeIndex}`}
                                style={{
                                    display: 'flex', alignItems: 'stretch', justifyContent: 'stretch',
                                    alignContent: 'stretch'
                                }}>
                            
                                <div key={index} className="group relative overflow-hidden shadow dark:shadow-gray-700 hover:shadow-md transition-all duration-100 " 
                                style={{ borderRadius: '20px', }}>
                                    <img style={{ width: '600px', height: '300px', objectFit: 'cover' }}
                                     src={ele.cover_image} alt="" />
                                    <div className="relative p-6">
                                        <div className="bagde-flag-wrap1">
                                            <img
                                                style={{
                                                    position: 'absolute',
                                                    top: '0',
                                                    right: '0',
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    zIndex: '2',
                                                }}
                                                src={ele.logo}
                                                alt="Logo"
                                            />
                                        </div>
                                        <div>

                                        <div className="color-price" 
                                        style={{ marginTop: '10px', marginLeft: '-10px', 
                                        position: 'relative', display: 'flex',
                                         justifyContent: 'flex-start' }}>
                          <div className="color-option">
                            <div className="flex items-center" style={{ display: 'flex', 
                                justifyContent: 'flex-start' }}>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Link 
                                                   onClick={()=>{ ele.verified===1 && handleClickCard(ele.bank_link)}}
                                                    // to={ele.detail_added === 1 &&
                                                    //      `/credits-loan-details/${ele.loan_id}/${activeIndex}`}
                                                     data-ripple-light="true"
                                                     data-tooltip-target="tooltip" 
                                                       className="color" 
                                                       style={{ fontWeight: 'bold', fontSize: '21px',
                                                        color: '#333333', maxWidth: 220, overflow: 'hidden', 
                                                        textOverflow: 'ellipsis', whiteSpace: 'nowrap', 
                                                        marginLeft: '5px', marginBottom: '6px' }}>
                                                            {ele.name}
                                                     </Link>
                                                     <div
                                  data-tooltip="tooltip"
                                  class="tooltip"
                                >
                                  {ele.name}
                                </div>
                                                </div>
                                                </div>
                                                </div>
                                                { ele.verified !=1 ? (<div className="price" 
                                                style={{ marginLeft: '35px',display:'flex',justifyContent:'flex-end' }}>
                                                  <div className="color-option">
                                                    <div className="flex items-center justify-center" 
                                                    style={{ marginTop: '-3px', textAlign: 'right' }}>
                                                      <Link style={{ fontWeight: 'bold', fontSize: '18px', 
                                                        textAlign: 'right', display: 'flex', justifyContent: 'center', 
                                                        alignContent: 'center', maxWidth: 200, overflow: 'hidden', 
                                                        textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} class="color">
                                                        Coming Soon
                                                      </Link>
                                                    
                                                    </div>
                                                  </div>
                                                </div>) : ''}
                                            </div>

                                            <div className="color-price" style={{
                                                marginTop: '10px',
                                                marginLeft: '-10px', position: 'relative', display: 'flex', 
                                                justifyContent: 'flex-start'
                                            }}>
                                                <div className="color-option">
                                                    <div className="flex items-center" style={{ display: 'flex', 
                                                        justifyContent: 'flex-end' }}>
                                                        <div style={{
                                                            fontSize: '26px', color: '#000000',
                                                            marginRight: '10px'
                                                        }}>
                                                            <span class="ion--location-outline"></span>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <Link 
                                                           onClick={()=>{ ele.verified===1 && handleClickCard(ele.bank_link)}}
                                                            // to={ele.detail_added === 1 && 
                                                            //     `/credits-loan-details/${ele.loan_id}/${activeIndex}`}
                                                            data-ripple-light="true"
                                                            data-tooltip-target="tooltip"   className="color" 
                                                            style={{ fontSize: '15px', color: '#000', maxWidth: 180,
                                                             overflow: 'hidden', textOverflow: 'ellipsis',
                                                              whiteSpace: 'nowrap' }}>
                                                               {ele.location}
                                                               <span className="underline"></span>
                                                               <div
                                  data-tooltip="tooltip"
                                  class="tooltip"
                                >
                                  {ele.location}
                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                           
                                            
                        </div>

                                            {/* <div className="color-price" style={{ marginTop: '30px', marginLeft: '-10px' }}>
                                                <div className="flex items-center">
                                                {ele.verified===1 && (
                                                    <div style={{ fontSize: '30px', color: '#000000', marginRight: '10px' }}>
                                                        <span style={{ marginLeft: '-3px' }} class="game-icons--money-stack"></span>
                                                    </div>
                                                )}
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        {ele.verified===1 && (
                                                        <Link 
                                                        onClick={()=>handleClickCard(ele.bank_link)}
                                                        // to={ele.detail_added === 1 && 
                                                        //     `/credits-loan-details/${ele.loan_id}/${activeIndex}`}
                                                            className="color" style={{ fontSize: '18px', color: '#858585', marginBottom: '4px', fontWeight: 'bold' }}>
                                                           Check the Bank </Link>
                                                           )}
                                                        {/* <Link to={ele.detail_added === 1 && `/credits-loan-details/${ele.loan_id}/${activeIndex}`}
                                                            className="color" style={{ fontSize: '16px', color: '#000000', fontWeight: 'bold', maxWidth: 170, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                            {ele.loan_name}
                                                        </Link> */}
                                                    {/* </div>
                                                </div>
                                            </div> */} 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))
                    }
                </div>
                : <Error />}

            </section>
             ) }
        </>
    )
}
