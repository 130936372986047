import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Select from "react-select";
import Switcher from "../../components/switcher";
import { Icon } from "@iconify/react";
import airplaneTakeoffDuotone from "@iconify/icons-ph/airplane-takeoff-duotone";
import { Link, useLocation, useNavigate } from "react-router-dom";
import star from "../../assets/images/rating-star.png";
import Tick from "../../assets/images/tick1.svg";
import Tick1 from "../../assets/images/travel-tick-icon.svg";
import World from "../../assets/images/world.png";
import { insurance } from "../../data/data";
import {
  LuClock,
  LuCalendarDays,
  RiDragDropLine,
  LuSearch,
  AiOutlineAppstore,
  AiOutlineStar,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  PiBrowsersLight,
  MdOutlineSportsVolleyball,
  AiOutlinePieChart,
  LuMusic4,
  TbCameraPlus,
  PiRocketLaunchBold,
  FaImages,
  ImImages,
  BiImage,
  BiImages,
  AiOutlineInsurance,
  AiFillInsurance,
  RiArrowDropDownLine,
} from "../../assets/icons/vander";
import axios from "axios";
import { REST_API_END_POINT } from "../../constants/DefaultValue";
import { empty } from "../../common/Helper";
import Error from "../special/error";
import { DNA, Hourglass } from "react-loader-spinner";

const navItems = [
  { text: "Travel", color: "black", id: 1 },
  { text: "Work", color: "black", id: 2 },
  { text: "Student", color: "black", id: 3 },
];


export default function InsuranceListing({ activIndex,setActivIndex, insuranceSelected,refresh,countryPrefer,setInsTypeId,insTypeId }) {
    const [companies, setCompanies] = useState([])
    const [loading, setLoading] = useState(true);
    // const [activIndex, setActivIndex] = useState(0);
    const [typeId, setTypeId] = useState(1)
    const [activeIndexx, setIndex] = useState(0);
    console.log("countryPrefer",countryPrefer);
    console.log("activIndex1111111111111",insTypeId);


  //   const getAllCompanyList = () => {
  //       axios.post(`${REST_API_END_POINT}insurance/company-list`, {from_user:true})
  //           .then((res) => {
  //               if (res.data.status == 1) {
  //                   const promises = res.data.data.map(eachRow => {
  //                       return axios.post(`${REST_API_END_POINT}insurance/get-all-insurance-form`, { c_id: !empty(insuranceSelected) ? insuranceSelected.id : eachRow?.id })
  //                           .then((res) => {
  //                               return res.data.data;
  //                           })
  //                           .catch((err) => {
  //                               console.error(err);
  //                               return [];
  //                           });
  //                   });
  //                   Promise.all(promises)
  //                       .then(insuranceData => {
  //                           const companiesWithInsurance = res.data.data.map((company, index) => ({
  //                               ...company,
  //                               insurance: insuranceData[index]
  //                           }));

  //                           setCompanies(
  //                             companiesWithInsurance
  //                               ?.filter((d) => d.status === 1)
  //                               // ?.sort((a, b) => a.name.localeCompare(b.name))
  //                               .map((company) => ({
  //                                 ...company,
  //                                 insurance: company.insurance
  //                                   .filter((row) =>  row.status === 1  &&  row.verified === 1)
  //                                   .sort((a, b) => a.name.localeCompare(b.name)),
  //                               }))
  //                           );
  //           })
  //           .catch((error) => {
  //             console.error("Error fetching insurance data:", error);
  //           });
  //       }
  //       else{
  //         setCompanies([])
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  const getAllCompanyList = () => {
    console.log("activIndex:", activIndex);
  
    axios.post(`${REST_API_END_POINT}insurance/company-list`, { from_user: true })
      .then((res) => {
        console.log("API response:", res.data);
  
        if (res.data.status == 1) {
          let filteredData = res.data.data;
          filteredData = filteredData.filter(d => d.status === 1);
          console.log("After status filter:", filteredData);
  
          filteredData = filteredData.filter(d => d.location?.toLowerCase().includes(countryPrefer.name.toLowerCase()));
          console.log("After location filter:", filteredData);
          if (activIndex === 0) {
            filteredData = filteredData
          }
          else if (activIndex === 1) {
            // Travel
            filteredData = filteredData.filter(d => d.insurance_type?.split(',').includes('1'));
          } else if (activIndex === 2) {
            // Work
            filteredData = filteredData.filter(d => d.insurance_type?.split(',').includes('2'));
          } else if (activIndex === 3) {
            // Student 
            filteredData = filteredData.filter(d => d.insurance_type?.split(',').includes('3'));
          }
  
          // Log the final filtered data
          console.log("Filtered data:", filteredData);
  
          setCompanies(filteredData);
        } else {
          setCompanies([]); // If status isn't 1, set an empty array
        }
      })
      .catch((err) => {
        // Log any errors that occur
        console.error("API error:", err);
      });
  };
  
    useEffect(() => {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 5000);
        getAllCompanyList()
        return () => clearTimeout(timer);
    }, [refresh,countryPrefer,activIndex])

    useEffect(() => {
        setCompanies([insuranceSelected])
    }, [insuranceSelected])


    const handleClickCard=(link)=>{
      window.open(link, '_blank')
    }   


    const handleItemClick = (index, type_id) => {
      setInsTypeId(type_id)
      setActivIndex(index);
      // setSearchInputValue('')
    };
    return (
      <>
      {/* <section className="relative mb-6" style={{ marginTop: "25px" }}>
        {companies?.length>0?
        <div
          className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[30px]"
          style={{ padding: "20px" }}
        >
          {companies?.map((ele, index) => (
            <Link key={index}
              style={{
                display: "flex",
                alignItems: "stretch",
                justifyContent: "stretch",
                alignContent: "stretch",
              }}
            >
              <div
                key={index}
                className="group relative overflow-hidden shadow dark:shadow-gray-700 
                hover:shadow-md transition-all duration-100 "
                style={{ borderRadius: "20px" }}
              >
                <img
                  style={{
                    maxWidth: "600px",
                    height: "300px",
                    objectFit: "cover",
                  }}
                  src={ele.cover_image}
                  alt={ele.name}
                />
                <div className="relative p-6">
                  <div className="bagde-flag-wrap1">
                    <img
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        zIndex: "2",
                      }}
                      src={ele.logo}
                      alt="Logo"
                    />
                  </div>
                  <div>
                    <div
                      className="color-price"
                      style={{
                        marginTop: "10px",
                        marginLeft: "-10px",
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div className="color-option1">
                        <div
                          className="flex items-center"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Link
                              data-ripple-light="true"
                                  data-tooltip-target="tooltip"
                              className="color"
                              style={{
                                fontWeight: "bold",
                                fontSize: "21px",
                                color: "#333333",
                                maxWidth: 150,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                marginLeft: "10px",
                                marginBottom: "6px",
                              }}
                            >
                              {ele.name}
                            </Link>
                            <div
                                  data-tooltip="tooltip"
                                  class="tooltip"
                                >
                                  {ele.name}
                                </div>
                          </div>
                        </div>
                      </div>
                      {ele.verified != 1 ? (
                                                    <div className="price" style={{ marginRight: '-15px' }}>
                                                        <div className="color-option">
                                                            <div className="flex items-center justify-center" style={{ marginTop: '0px', textAlign: 'center' }}>
                                                                <Link style={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'center', display: 'flex', justifyContent: 'right', alignContent: 'center', maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} class="color">
                                                                    Coming Soon
                                                                </Link>                                                              
                      </div>
                                                        </div>
                                                    </div>
                                                ) : ''}
                                                
                    </div>

                    <div
                      className="color-price"
                      style={{
                        marginTop: "10px",
                        marginLeft: "-10px",
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div className="color-option">
                        <div
                          className="flex items-center"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "28px",
                              color: "#000000",
                              marginRight: "18px",
                            }}
                          >
                            <span class="ion--location-outline"></span>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Link
                              className="color"
                                data-ripple-light="true"
                                  data-tooltip-target="tooltip"
                              style={{
                                fontSize: "15px",
                                color: "#000",
                                maxWidth: 150,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {ele.location}
                              <span className="underline"></span>
                            </Link>
                            <div
                                  data-tooltip="tooltip"
                                  class="tooltip"
                                >
                                 {ele.location}
                                </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="color-price"
                      style={{ marginTop: "30px", marginLeft: "-10px" }}
                    >
                      <div className="flex ">
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Link
                            className="color"
                            style={{
                              fontSize: "18px",
                              color: "#858585",
                              marginBottom: "4px",
                              fontWeight: "bold",
                            }}
                          >
                            Assurance given
                          </Link>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "10px",
                            }}
                          >
                            {ele?.verified && ele.insurance.length > 0 ? (
                              ele.insurance.map((row) => (
                                <div key={row.id}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "10px",
                                  }}
                                >                            
                                  <div
                                    style={{
                                      fontSize: "25px",
                                      color: "#000000",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <span
                                      style={{ marginLeft: "-3px" }}
                                      class="streamline--insurance-hand"
                                    ></span>
                                  </div>                               
                                    <Link
                                      to={
                                        ele.verified === 1 && row.verified===1 && row.status===1 &&
                                        ele.status === 1 &&
                                        `/insurance-details/${row.id}/${activeIndex}`
                                      }                                    
                                      className="color underlineeee"
                                      style={{
                                        fontSize: "16px",
                                        color: "#000000",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        marginTop: "5px",
                                        maxWidth: 150,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {row.name}
                                    </Link>                              
                                </div>
                              ))
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginTop: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: "25px",
                                    color: "#000000",
                                    marginRight: "10px",
                                  }}
                                >
                                  <span
                                    style={{ marginLeft: "-3px" }}
                                    class="streamline--insurance-hand"
                                  ></span>
                                </div>
                                <Link
                                  className="color"
                                  style={{
                                    fontSize: "16px",
                                    color: "#000000",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                    marginTop: "5px",
                                  }}
                                >
                                  {"Not Yet Added"}
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
        : <Error />}
      </section> */}
        {/* the above is the real one but for the initial Phase we create like this*/}

        {/* <section className="relative -mt-16">
        <div className="container">
          <div className="lg:flex">
            <div
              className="p-3 w-full"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="flex flex-wrap justify-between">
              
                <div className="wrapper4" style={{ marginTop: "0px" }}>
                  <div
                    className="nav-container"
                    style={{borderRadius: "13px", }}
                  >
                    <nav className="nav">
                      {navItems.map((item, index) => (
                        <a
                          key={index}
                          className={`nav-item font-semibold ${index === activIndex ? "is-active" : ""
                            }`}
                          active-color={item.color}
                          onClick={() => handleItemClick(index, item.id)}
                          style={{
                            color:
                              index === activIndex ? item.color : "#83818c",
                          }}
                        >
                          {item.text}
                        </a>
                      ))}
                      <span
                        className="nav-indicator"
                        style={{
                          width: `${100 / navItems.length}%`,
                          left: `${activIndex * (100 / navItems.length) +
                            (activIndex == 0 ? 3 : activIndex == 2 ? -4 : 0)
                            }%`,
                          backgroundColor: navItems[activIndex].color,
                        }}
                      ></span>
                    </nav>
                  </div>
                </div>

               
              </div>
            </div>
          </div>
        </div>
      </section> */}


        {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh',
          }}
        >
        <DNA
        visible={true}
        height="140"
        width="140"     
        ariaLabel="dna-loading"
        wrapperStyle={{}}
         wrapperClass="dna-wrapper"
           />
        </div>
      ) : (
        <section className="relative mb-6" style={{ marginTop: "25px" }}>
        {companies?.length>0?
        <div
          className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[30px]"
          style={{ padding: "20px" }}
        >
          {companies?.map((ele, index) => (
            <Link key={index}
              style={{
                display: "flex",
                alignItems: "stretch",
                justifyContent: "stretch",
                alignContent: "stretch",
              }}
              onClick={()=>handleClickCard(ele.insurance_link)}
            >
              <div
                key={index}
                className="group relative overflow-hidden shadow dark:shadow-gray-700 
                hover:shadow-md transition-all duration-100 "
                style={{ borderRadius: "20px" }}
              >
                <img
                  style={{
                    width: "600px",
                    height: "300px",
                    objectFit: "cover",
                  }}
                  onClick={()=>handleClickCard(ele.insurance_link)}
                  src={ele.cover_image}
                  alt={ele.name}
                />
                <div className="relative p-6">
                  <div className="bagde-flag-wrap1">
                    <img
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        zIndex: "2",
                      }}
                      onClick={()=>handleClickCard(ele.insurance_link)}
                      src={ele.logo}
                      alt="Logo"
                    />
                  </div>

                 {ele.verified != 1 ?( <div>
                    <div
                      className="color-price"
                      style={{
                        marginTop: "10px",
                        marginLeft: "-10px",
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div className="color-option1">
                        <div
                          className="flex items-center"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Link
                              data-ripple-light="true"
                                  data-tooltip-target="tooltip"
                              className="color"
                              style={{
                                fontWeight: "bold",
                                fontSize: "21px",
                                color: "#333333",
                                maxWidth: 150,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                marginLeft: "10px",
                                marginBottom: "6px",
                              }}
                              onClick={()=>handleClickCard(ele.insurance_link)}
                            >
                              {ele.name}
                            </Link>
                            <div
                                  data-tooltip="tooltip"
                                  class="tooltip"
                                  onClick={()=>handleClickCard(ele.insurance_link)}
                                >
                                  {ele.name}
                                </div>
                          </div>
                        </div>
                      </div>
                    
                                                    <div className="price" style={{ marginRight: '-15px' }}>
                                                        <div className="color-option">
                                                            <div className="flex items-center justify-center" 
                                                            style={{ marginTop: '0px', textAlign: 'center' }}>
                                                                <Link style={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'center', 
                                                                display: 'flex', justifyContent: 'right', alignContent: 'center',
                                                                   maxWidth: 200, overflow: 'hidden', 
                                                                   textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} class="color">
                                                                    Coming Soon
                                                                </Link>                                                              
                      </div>
                                                        </div>
                                                    </div>
                                                
                                                
                    </div>

                    <div
                      className="color-price"
                      style={{
                        marginTop: "10px",
                        marginLeft: "-10px",
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div className="color-option">
                        <div
                          className="flex items-center"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "28px",
                              color: "#000000",
                              marginRight: "18px",
                            }}
                            onClick={()=>handleClickCard(ele.insurance_link)}
                          >
                            <span class="ion--location-outline"></span>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Link
                              className="color"
                                data-ripple-light="true"
                                  data-tooltip-target="tooltip"
                                  onClick={()=>handleClickCard(ele.insurance_link)}
                              style={{
                                fontSize: "15px",
                                color: "#000",
                                maxWidth: 150,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {ele.location}
                              {/* <span className="underline"></span> */}
                            </Link>
                            <div
                                  data-tooltip="tooltip"
                                  class="tooltip"
                                >
                                 {ele.location}
                                </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* {ele.verified===1 && (
                    <div
                      className="color-price"
                      style={{ marginTop: "30px", marginLeft: "-10px" }}
                    >
                      <div className="flex ">
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                         <Link
                            className="color"
                            onClick={()=>handleClickCard(ele.insurance_link)}
                            style={{
                              fontSize: "18px",
                              color: "#858585",
                              marginBottom: "4px",
                              fontWeight: "bold",
                            }}
                          >
                           Check Insurance
                          </Link> 





                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "10px",
                            }}
                          >
                            {ele?.verified && ele.insurance.length > 0 ? (
                              ele.insurance.map((row) => (
                                <div key={row.id}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "10px",
                                  }}
                                >                            
                                  <div
                                    style={{
                                      fontSize: "25px",
                                      color: "#000000",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <span
                                      style={{ marginLeft: "-3px" }}
                                      class="streamline--insurance-hand"
                                    ></span>
                                  </div>                               
                                    <Link
                                      to={
                                        ele.verified === 1 && row.verified===1 && row.status===1 &&
                                        ele.status === 1 &&
                                        `/insurance-details/${row.id}/${activeIndex}`
                                      }                                    
                                      className="color underlineeee"
                                      style={{
                                        fontSize: "16px",
                                        color: "#000000",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        marginTop: "5px",
                                        maxWidth: 150,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {row.name}
                                    </Link>                              
                                </div>
                              ))
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginTop: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: "25px",
                                    color: "#000000",
                                    marginRight: "10px",
                                  }}
                                >
                                  <span
                                    style={{ marginLeft: "-3px" }}
                                    class="streamline--insurance-hand"
                                  ></span>
                                </div>
                                <Link
                                  className="color"
                                  style={{
                                    fontSize: "16px",
                                    color: "#000000",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                    marginTop: "5px",
                                  }}
                                >
                                  {"Not Yet Added"}
                                </Link>
                              </div>
                            )}
                          </div> 
                        </div>
                      </div>
                    </div>
                    )} */}
                  </div>):( <div>
                    <div
                      className="color-price"
                      style={{
                        marginTop: "10px",
                        marginLeft: "-10px",
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div className="color-option1">
                        <div
                          className="flex items-center"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Link
                              data-ripple-light="true"
                                  data-tooltip-target="tooltip"
                              className="color"
                              style={{
                                fontWeight: "bold",
                                fontSize: "21px",
                                color: "#333333",
                                maxWidth: 250,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                marginLeft: "10px",
                                marginBottom: "6px",
                              }}
                              onClick={()=>handleClickCard(ele.insurance_link)}
                            >
                              {ele.name}
                            </Link>
                            <div
                                  data-tooltip="tooltip"
                                  class="tooltip"
                                  onClick={()=>handleClickCard(ele.insurance_link)}
                                >
                                  {ele.name}
                                </div>
                          </div>
                        </div>
                      </div>
                      {/* {ele.verified != 1 ? (
                                                    <div className="price" style={{ marginRight: '-15px' }}>
                                                        <div className="color-option">
                                                            <div className="flex items-center justify-center" 
                                                            style={{ marginTop: '0px', textAlign: 'center' }}>
                                                                <Link style={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'center', 
                                                                display: 'flex', justifyContent: 'right', alignContent: 'center',
                                                                   maxWidth: 200, overflow: 'hidden', 
                                                                   textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} class="color">
                                                                    Coming Soon
                                                                </Link>                                                              
                      </div>
                                                        </div>
                                                    </div>
                                                ) : ''} */}
                                                
                    </div>

                    <div
                      className="color-price"
                      style={{
                        marginTop: "10px",
                        marginLeft: "-10px",
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div className="color-option">
                        <div
                          className="flex items-center"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "28px",
                              color: "#000000",
                              marginRight: "18px",
                            }}
                            onClick={()=>handleClickCard(ele.insurance_link)}
                          >
                            <span class="ion--location-outline"></span>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Link
                              className="color"
                                data-ripple-light="true"
                                  data-tooltip-target="tooltip"
                                  onClick={()=>handleClickCard(ele.insurance_link)}
                              style={{
                                fontSize: "15px",
                                color: "#000",
                                maxWidth: 150,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {ele.location}
                              {/* <span className="underline"></span> */}
                            </Link>
                            <div
                                  data-tooltip="tooltip"
                                  class="tooltip"
                                >
                                 {ele.location}
                                </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* {ele.verified===1 && (
                    <div
                      className="color-price"
                      style={{ marginTop: "30px", marginLeft: "-10px" }}
                    >
                      <div className="flex ">
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                         <Link
                            className="color"
                            onClick={()=>handleClickCard(ele.insurance_link)}
                            style={{
                              fontSize: "18px",
                              color: "#858585",
                              marginBottom: "4px",
                              fontWeight: "bold",
                            }}
                          >
                           Check Insurance
                          </Link> 





                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "10px",
                            }}
                          >
                            {ele?.verified && ele.insurance.length > 0 ? (
                              ele.insurance.map((row) => (
                                <div key={row.id}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "10px",
                                  }}
                                >                            
                                  <div
                                    style={{
                                      fontSize: "25px",
                                      color: "#000000",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <span
                                      style={{ marginLeft: "-3px" }}
                                      class="streamline--insurance-hand"
                                    ></span>
                                  </div>                               
                                    <Link
                                      to={
                                        ele.verified === 1 && row.verified===1 && row.status===1 &&
                                        ele.status === 1 &&
                                        `/insurance-details/${row.id}/${activeIndex}`
                                      }                                    
                                      className="color underlineeee"
                                      style={{
                                        fontSize: "16px",
                                        color: "#000000",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        marginTop: "5px",
                                        maxWidth: 150,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {row.name}
                                    </Link>                              
                                </div>
                              ))
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginTop: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: "25px",
                                    color: "#000000",
                                    marginRight: "10px",
                                  }}
                                >
                                  <span
                                    style={{ marginLeft: "-3px" }}
                                    class="streamline--insurance-hand"
                                  ></span>
                                </div>
                                <Link
                                  className="color"
                                  style={{
                                    fontSize: "16px",
                                    color: "#000000",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                    marginTop: "5px",
                                  }}
                                >
                                  {"Not Yet Added"}
                                </Link>
                              </div>
                            )}
                          </div> 
                        </div>
                      </div>
                    </div>
                    )} */}
                  </div>)}

                </div>
              </div>
            </Link>
          ))}
        </div>
        : <Error />}
      </section> ) }
    </>
  );
}

