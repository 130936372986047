import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Select from "react-select";
import Switcher from "../../components/switcher";
import { Icon } from "@iconify/react";
import airplaneTakeoffDuotone from "@iconify/icons-ph/airplane-takeoff-duotone";
import { Link, useNavigate } from "react-router-dom";
import { data, visaDetails } from "../../data/data";

import {
  LuClock,
  LuCalendarDays,
  RiDragDropLine,
  LuSearch,
  AiOutlineAppstore,
  AiOutlineStar,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  PiBrowsersLight,
  MdOutlineSportsVolleyball,
  AiOutlinePieChart,
  LuMusic4,
  TbCameraPlus,
  PiRocketLaunchBold,
  FaImages,
  ImImages,
  BiImage,
  BiImages,
  RiArrowDropDownLine,
} from "../../assets/icons/vander";
import axios from "axios";
import { EXCHANGE_BASE_URL, REST_API_END_POINT } from "../../constants/DefaultValue";
import { empty } from "../../common/Helper";
import Error from "../special/error";
import { DNA } from "react-loader-spinner";
import localforage from "localforage";

const formatNumber = (number) => {
  if (number < 1000) {
    return number
  } else if (number < 1000000) {
    return (number / 1000).toFixed(1) + 'K';
  } else {
    return (number / 1000000).toFixed(1) + 'M';
  }
};

const getCurrencyCodeAndSymbol = async (country) => {
  console.log("Fetching currency code and symbol for country:", country);
  const url = `https://restcountries.com/v3.1/name/${country}`;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      console.error('Network response was not ok:', response.statusText);
      return null;
    }
    const data = await response.json();
    console.log("API response data:", data);

    const index = country.toLowerCase() === 'oman' ? 1 : 0;
    const countryData = data[index];
    console.log("Selected country data:", countryData);

    if (countryData && countryData.currencies) {
      const currencyCode = Object.keys(countryData.currencies)[0];
      const currencySymbol = countryData.currencies[currencyCode].symbol;
      console.log("Currency code and symbol:", { currencyCode, currencySymbol });

      return { currencyCode, currencySymbol };
    } else {
      console.error('Error fetching currency code for country:', country);
      return null;
    }
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

const getExchangeRate = async (fromCountry, toCountry) => {
  const cacheKey = `${fromCountry}-${toCountry}`;
  console.log("Cache key:", cacheKey);

  try {
    const cachedData = await localforage.getItem(cacheKey);
    if (cachedData) {
      console.log(`Cache hit for ${cacheKey}:`, cachedData);
      return cachedData;
    }
  } catch (error) {
    console.error('Error retrieving cached data:', error);
  }

  try {
    const [fromCurrencyDetails, toCurrencyDetails] = await Promise.all([
      getCurrencyCodeAndSymbol(fromCountry),
      getCurrencyCodeAndSymbol(toCountry),
    ]);

    console.log("From currency details:", fromCurrencyDetails);
    console.log("To currency details:", toCurrencyDetails);

    if (!fromCurrencyDetails || !toCurrencyDetails) {
      console.error('Invalid country name provided or error fetching currency details.');
      return null;
    }

    const { currencyCode: fromCurrency, currencySymbol: fromSymbol } = fromCurrencyDetails;
    const { currencyCode: toCurrency, currencySymbol: toSymbol } = toCurrencyDetails;

    const url = `${EXCHANGE_BASE_URL}/${fromCurrency}/${toCurrency}`;
    console.log(`Fetching exchange rate from URL: ${url}`);
    const response = await fetch(url);
    if (!response.ok) {
      console.error('Network response was not ok:', response.statusText);
      return null;
    }

    const data = await response.json();
    console.log(`Fetched exchange rate data for ${cacheKey}:`, data);

    if (data && data.conversion_rate) {
      const exchangeRate = data.conversion_rate;
      const rateData = { exchangeRate, fromSymbol, toSymbol };
      console.log(`Exchange rate data for ${cacheKey}:`, rateData);

      try {
        await localforage.setItem(cacheKey, rateData);
      } catch (error) {
        console.error('Error storing cached data:', error);
      }
      
      return rateData;
    } else {
      console.error('Error fetching exchange rate:', data.error);
      return null;
    }
  } catch (error) {
    console.error('Error during fetch operation:', error);
    return null;
  }
};




export default function VisaListing({ 
  activeIndex,
  VISA_REQUIREMENT,
  navItems,
   countryPrefer,
    countrySelected,
    refresh,
    setTypeId,
    typeId,
    setVisaSelect,
    setSearchInputValue,
  }) {
  const navigate = useNavigate()
  const [exchangeRates, setExchangeRates] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(VISA_REQUIREMENT[0]);
  const [activeIndexx, setIndex] = useState(0);
  const [countries, setCountries] = useState([]);
  const [activIndex, setActivIndex] = useState(0);
  const [loading, setLoading] = useState(true);


  const handleItemClick = (index, type_id) => {
    setTypeId(type_id)
    setActivIndex(index);
    setSearchInputValue('')
  };


  const matchCategory = (category) => {
    setSelectedCategory(category);
    setVisaSelect(category)
    setSearchInputValue('')
  };

console.log("selectedCategoryselectedCategory",selectedCategory);

  const fetchCountries = () => {
    if (selectedCategory && countryPrefer) {
      axios.post(`${REST_API_END_POINT}country/list-for-user`, {
        typeId, visa_required_type: selectedCategory.id, c_id: countryPrefer.id
      }).
        then((res) => {
          if (!empty(res.data.data)) {
            setCountries(res.data.data);
          } else {
            setCountries([])
          }
        });
    }
  };
  useEffect(() => {
    setCountries([countrySelected])
    
  }, [countrySelected])

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);
    fetchCountries();
    // getCurrencyCodeAndSymbol()
    // getExchangeRate()
    localStorage.removeItem('visaTypeId')
    return () => clearTimeout(timer);
  }, [typeId, activeIndexx, countryPrefer, selectedCategory,refresh]);

console.log("activeIndexxactiveIndexx",activeIndexx);

  useEffect(() => {
    // Clear the cache once before fetching new data
    localforage.clear().then(() => {
      console.log('Cache cleared');
    }).catch((error) => {
      console.error('Error clearing cache:', error);
    });
  
    if (countryPrefer && countryPrefer.name && countries.length > 0) {
      countries.forEach(async (country) => {
        if (country && country.name) {
          const rateData = await getExchangeRate(countryPrefer.name, country.name);
          if (rateData) {
            setExchangeRates((prevRates) => ({
              ...prevRates,
              [`${countryPrefer.name}-${country.name}`]: rateData,
            }));
          }
        } else {
          console.error('Country name is undefined for country:', country);
        }
      });
    }
  }, [countries, countryPrefer]);
  

  return (
    <>
      <section className="relative md:pb-24 pb-16 -mt-16">
        <div className="container">
          <div className="lg:flex">
            <div
              className="p-6 w-full"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="flex flex-wrap justify-between">
                {/* First color-price */}
                <div className="wrapper4" style={{ marginTop: "0px" }}>
                  <div
                    className="nav-container"
                    style={{ paddingBottom: "10px", borderRadius: "13px", }}
                  >
                    <nav className="nav">
                      {navItems?.map((item, index) => (
                        <a
                          key={index}
                          className={`nav-item font-semibold ${index === activIndex ? "is-active" : ""
                            }`}
                          active-color={item.color}
                          onClick={() => handleItemClick(index, item.id)}
                          style={{
                            color:
                              index === activIndex ? item.color : "#83818c",
                          }}
                        >
                          {item.text}
                        </a>
                      ))}
                      <span
                        className="nav-indicator"
                        style={{
                          width: `${100 / navItems?.length}%`,
                          left: `${activIndex * (100 / navItems?.length) +
                            (activIndex == 0 ? 3 : activIndex == 2 ? -4 : 0)
                            }%`,
                          backgroundColor: navItems[activIndex].color,
                        }}
                      ></span>
                    </nav>
                  </div>
                </div>

                {/* Third color-price */}
                <div className="wrapper4" style={{ marginTop: "0px" }}>
                  <ul
                    className="justify-center text-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-8001"
                    id="myTab"
                    data-tabs-toggle="#StarterContent"
                    role="tablist"
                    style={{ borderRadius: "13px", padding: "10px" }}
                  >
                    <li
                      role="presentation"
                      className="md:inline-block block md:w-fit w-full"
                    >
                      <button
                        className={`px-4 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${activeIndexx === 0 ? "text-white bg-violet-6001" : ""
                          }`}
                        id="wednesday-tab"
                        data-tabs-target="#wednesday"
                        type="button"
                        role="tab"
                        aria-controls="wednesday"
                        aria-selected="true"
                        style={{ color: "black" }}
                        onClick={() => {
                          setIndex(0);
                          matchCategory(VISA_REQUIREMENT[0]);
                        }}
                      >
                        All
                      </button>
                    </li>

                    <li
                      role="presentation"
                      className="md:inline-block block md:w-fit w-full"
                    >
                      <button
                        className={`px-4 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${activeIndexx === 1 ? "text-white bg-violet-6001" : ""
                          }`}
                        id="thursday-tab"
                        data-tabs-target="#thursday"
                        type="button"
                        onClick={() => {
                          setIndex(1);
                          matchCategory(VISA_REQUIREMENT[1]);
                        }}
                        role="tab"
                        aria-controls="thursday"
                        aria-selected="false"
                        style={{ color: "black" }}
                      >
                        Visa Required
                      </button>
                    </li>

                    <li
                      role="presentation"
                      className="md:inline-block block md:w-fit w-full"
                    >
                      <button
                        className={`px-4 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${activeIndexx === 2 ? "text-white bg-violet-6001" : ""
                          }`}
                        id="friday-tab"
                        data-tabs-target="#friday"
                        type="button"
                        onClick={() => {
                          setIndex(2);
                          matchCategory(VISA_REQUIREMENT[2]);
                        }}
                        role="tab"
                        aria-controls="friday"
                        aria-selected="false"
                        style={{ color: "black" }}
                      >
                        Visa On Arrival
                      </button>
                    </li>

                    <li
                      role="presentation"
                      className="md:inline-block block md:w-fit w-full"
                    >
                      <button
                        className={`px-4 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${activeIndexx === 3 ? "text-white bg-violet-6001" : ""
                          }`}
                        id="saturday-tab"
                        data-tabs-target="#saturday"
                        type="button"
                        onClick={() => {
                          setIndex(3);
                          matchCategory(VISA_REQUIREMENT[3]);
                        }}
                        role="tab"
                        aria-controls="saturday"
                        aria-selected="false"
                        style={{ color: "black" }}
                      >
                        eVisa
                      </button>
                    </li>

                    <li
                      role="presentation"
                      className="md:inline-block block md:w-fit w-full"
                    >
                      <button
                        className={`px-4 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${activeIndexx === 5 ? "text-white bg-violet-6001" : ""
                          }`}
                        id="monday-tab"
                        data-tabs-target="#monday"
                        type="button"
                        onClick={() => {
                          setIndex(5);
                          matchCategory(VISA_REQUIREMENT[5]);
                        }}
                        role="tab"
                        aria-controls="monday"
                        aria-selected="false"
                        style={{ color: "black" }}
                      >
                        eTA
                      </button>
                    </li>

                    <li
                      role="presentation"
                      className="md:inline-block block md:w-fit w-full"
                    >
                      <button
                        className={`px-4 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${activeIndexx === 4 ? "text-white bg-violet-6001" : ""
                          }`}
                        id="sunday-tab"
                        data-tabs-target="#sunday"
                        type="button"
                        onClick={() => {
                          setIndex(4);
                          matchCategory(VISA_REQUIREMENT[4]);
                        }}
                        role="tab"
                        aria-controls="sunday"
                        aria-selected="false"
                        style={{ color: "black" }}
                      >
                        Visa free
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh',
          }}
        >
        <DNA
        visible={true}
        height="140"
        width="140"     
        ariaLabel="dna-loading"
        wrapperStyle={{}}
         wrapperClass="dna-wrapper"
           />
        </div>
      ) : (
        <section className="relative mb-6" style={{ marginTop: "-90px" }}>
           {countries?.length > 0 ?
          <div
            className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-[30px]"
            style={{
              padding: "20px",
              display: "flex",
              alignItems: "stretch",
              justifyContent: "stretch",
              alignContent: "stretch",
            }}
          >
            {countries?.map((ele, index) => (
          
              
              <div key={index}
                onClick={() => {
                  ele.verified === 1 && navigate(
                    `/visa-details/${ele.id}/${activeIndex}/${ele.name}`,
                    { state: { visaTypeId: typeId } }
                  )
                  localStorage.setItem('visaTypeId', JSON.stringify(typeId))
                }}
                style={{
                  display: "flex",
                  alignItems: "stretch",
                  justifyContent: "stretch",
                  alignContent: "stretch", cursor: 'pointer'
                }}
              >
                   { console.log("eleeleeleeleele",ele)}
                <div
                  key={index}
                  className="group relative overflow-hidden shadow dark:shadow-gray-700 hover:shadow-md transition-all duration-100 "
                  style={{ borderRadius: "20px" ,zIndex:1}}
                >
                  <img
                    style={{
                      width: "600px",
                      height: "300px",
                      objectFit: "cover",
                    }}
                    src={ele.image}
                    alt=""
                  />
                  <div className="relative p-6">
                    {ele?.verified === 1 && (
                      <div
                        className="bagde-flag-wrap"
                        style={{ marginTop: "-50px" }}
                      >
                        <span className="bagde-flag">{formatNumber(ele.no_of_visa)} Visa Lodged</span>
                      </div>
                    )}
                    {ele.is_passport_ranking===1&&<span
                      style={{
                        top: "-280px",
                        right: "10px",
                        position: "absolute",
                        backdropFilter: "blur(1000px)",
                      }}
                      className="px-2 color text-white text-sm font-semibold rounded-lg shadow items-center"
                    >
                      <li className="inline-block m-2">


                        <Link to="#" style={{ backdropFilter: "blur(10px)" }}>
                        Passport Ranking # {formatNumber(ele.passport_ranking)}

                          {/* {ele.visa_requirement === 1 ? 'Visa Required' : ele.visa_requirement === 2 ?
                            "Visa On Arrival" : ele.visa_requirement === 3 ? 'eVisa' : ele.visa_requirement === 5 ? 'eTA' : 'Visa Free'}
                             */}
                        </Link>
                      </li>
                    </span>}

                    <div>
                      {/* <div className="flex mb-4">
                                                <span className="text-slate-400 text-[16px] inline-flex items-center">{ele.date}</span>
                                                <span className="text-slate-400 text-[16px] ms-3 inline-flex items-center">5 min read</span>
                                            </div> */}

                      <div className="color-price" style={{ marginTop: '10px', marginLeft: '-10px', position: 'relative', display: 'flex', justifyContent: 'space-between' }}>
                        <div className="color-option1" style={{display:'flex',width:210}} >
                          <div className="flex items-center" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Link
                              to={ele.verified === 1 && `/visa-details/${ele.id}/${activeIndex}/${ele.name}`}
                              className="color"
                              data-ripple-light="true" data-tooltip-target="tooltip" style={{ fontWeight: 'bold', fontSize: '21px', color: '#333333', maxWidth: 170, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: '-3px', marginBottom: '6px' }}>{ele.name}
                            </Link>
                            <div
                                                                data-tooltip="tooltip"
                                                             class="tooltip"
                                                               >
                                                         {ele.name} 
                                                          </div>
                          </div>
                          
                          </div>
                          <div className="color-option1" style={{ position: 'relative' }}>
  <Link
    className="color"
    style={{
      fontWeight: 'bold',
      fontSize: '14px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      maxWidth: '110px',
    }}
    onMouseEnter={() => {
      document.getElementById(`tooltip-${ele.id}`).style.display = 'block';
    }}
    onMouseLeave={() => {
      document.getElementById(`tooltip-${ele.id}`).style.display = 'none';
    }}
  >
    {(() => {
      const visaRequirements = ele?.visa_requirement?.split(',');
      const visaLabels = visaRequirements?.map(req => {
        switch (req) {
          case '1':
            return 'Visa Required';
          case '2':
            return 'Visa On Arrival';
          case '3':
            return 'eVisa';
          case '5':
            return 'eTA';
          default:
            return 'Visa Free';
        }
      });

      if(visaLabels){
        if (visaLabels?.length === 1) {
        return visaLabels[0];
      } else {
        return `${visaLabels[0]} & more`;
      }
    }

    })()}
  </Link>
  <div
    id={`tooltip-${ele?.id}`}
    style={{
      display: 'none',
      position: 'absolute',
      backgroundColor: '#333',
      color: '#fff',
      padding: '5px',
      borderRadius: '5px',
      whiteSpace: 'nowrap',
      fontSize: '12px',
      zIndex: 1000,
      top: '-80%',
      left: '50%',
      transform: 'translateX(-50%)',
    }}
    dangerouslySetInnerHTML={{
      __html: ele?.visa_requirement
        .split(',')
        .map(req => {
          switch (req) {
            case '1':
              return 'Visa Required';
            case '2':
              return 'Visa On Arrival';
            case '3':
              return 'eVisa';
            case '5':
              return 'eTA';
            default:
              return 'Visa Free';
          }
        })
        .join('<br />'),
    }}
  />
</div>





                          <div className="price" style={{ marginRight: "-15px" }}>
                            <div className="color-option">
                              <Link
                                to={ele.verified === 1 && `/visa-details/${ele?.id}/${activeIndex}/${ele?.name}`}
                                className="color"
                              >
                                {ele?.verified !== 1 ? 'Coming Soon' : ''}
                              </Link>
                            </div>
                          </div>
                        </div>
                        {ele?.verified === 1 && (
                          <div
                            className="color-price"
                            style={{ marginTop: "10px", marginLeft: "-10px" }}
                          >
                            <div className="color-option">
                              <Link
                                to={ele.verified === 1 && `/visa-details/${ele.id}/${activeIndex}/${ele.name}`}
                                class="color"
                                style={{ fontSize: "15px" }}
                              >
                                Visa Processing Time <br/>
                                
                              </Link>
                            </div>
                            <div className="price" style={{ marginRight: "-15px" }}>
                              <div className="color-option">
                                <div className="flex items-center">
                                  <Link
                                    to={ele.verified === 1 && `/visa-details/${ele.id}/${activeIndex}/${ele.name}`}
                                    class="color"
                                    style={{ fontSize: "15px", color: "black" }}
                                  >
                                    Local Currency
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {ele?.verified === 1 && (
                          <div
                            className="color-price"
                            style={{ marginTop: "0px", marginLeft: "-10px" }}
                          >
                            <div className="color-option">
                              <Link
                                to={ele.verified === 1 && `/visa-details/${ele.id}/${activeIndex}/${ele.name}`}
                                class="color"
                                style={{
                                  fontSize: "17px",
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              >
                               {ele.get_visa_on} {' '} {ele.get_visa_on_unit}
                              </Link>
                            </div>
                            <div className="price" style={{ marginRight: "-15px" }}>
                              <div className="color-option">
                                <div className="flex items-center">
                                  <div
                                    style={{
                                      fontSize: "23px",
                                      color: "black",
                                      marginRight: "5px",
                                    }}
                                  >
                                    <span
                                      style={{ color: "black" }}
                                      // className="mdi--dollar"
                                    />
                                  </div>                               
                                  <Link
                                    key={index}
                                    to={ele.verified === 1 && `/visa-details/${ele.id}/${index}/${ele.name}`}
                                    class="color"
                                style={{
                                  fontSize: "17px",
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                                  >
                                    {/* {ele && exchangeRates[`${countryPrefer.name}-${ele.name}`] && (
                                    <>
                                      {exchangeRates[`${countryPrefer.name}-${ele.name}`].fromSymbol} 1 
                                      {exchangeRates[`${countryPrefer.name}-${ele.name}`].fromCurrency} = 
                                      {exchangeRates[`${countryPrefer.name}-${ele.name}`].toSymbol} 
                                      {(() => {
                                        const rate = exchangeRates[`${countryPrefer.name}-${ele.name}`].exchangeRate;
                                        let formattedRate;

                                        // If the rate is greater than or equal to 1, show exactly 2 decimal places
                                        if (rate >= 1) {
                                          formattedRate = rate.toFixed(2);
                                        } 
                                        // If the rate is less than 1, round to 2 significant digits
                                        else if (rate < 1) {
                                          formattedRate = parseFloat(rate.toPrecision(2)); // Round to 2 significant digits
                                        }

                                        return formattedRate;
                                      })()}
                                    </>
                                  )} */}
                                   {ele && exchangeRates[`${countryPrefer.name}-${ele.name}`] && (
                                    <>
                                    
                                      {exchangeRates[`${countryPrefer.name}-${ele.name}`].fromSymbol} 1 
                                      {exchangeRates[`${countryPrefer.name}-${ele.name}`].fromCurrency} = 
                                      {exchangeRates[`${countryPrefer.name}-${ele.name}`].toSymbol} 
                                      {(exchangeRates[`${countryPrefer.name}-${ele.name}`].exchangeRate).toFixed(2)}
                                    </>
                                  )}
                                  </Link>
                                  {/* {console.log("qqqqqqqqqq",exchangeRates[`${countryPrefer.name}-${ele.name}`].fromSymbol)} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
          ))}
              </div>
              : <Error />}
        {/* load more is below commented one  */ }
        {/* <div
          className="container mt-8"
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div className="flex items-center">
            <Link
              style={{
                color: "#000000",
                fontWeight: "bold",
                fontSize: "20px",
                cursor: "pointer",
                marginTop: "15px",
              }}
            >
              Load More
            </Link>
            <div
              style={{
                marginTop: "17px",
                fontSize: "40px",
                color: "#000000",
                cursor: "pointer",
              }}
            >
              <RiArrowDropDownLine style={{ color: "#000000" }} />
            </div>
          </div>
        </div> */}
      </section>
      )}
    </>
  );
}
