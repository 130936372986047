import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer';
import Select from 'react-select'
import Switcher from '../../components/switcher';
import { Icon } from '@iconify/react';
import airplaneTakeoffDuotone from '@iconify/icons-ph/airplane-takeoff-duotone';
import { Link, useNavigate } from 'react-router-dom';
import { consultants } from '../../data/data';
import star from '../../assets/images/rating-star.png';
import { LuClock, LuCalendarDays, RiDragDropLine, LuSearch, AiOutlineAppstore, AiOutlineStar, MdKeyboardArrowLeft, MdKeyboardArrowRight, PiBrowsersLight, MdOutlineSportsVolleyball, AiOutlinePieChart, LuMusic4, TbCameraPlus, PiRocketLaunchBold, FaImages, ImImages, BiImage, BiImages, RiArrowDropDownLine } from "../../assets/icons/vander"
import axios from 'axios';
import { REST_API_END_POINT } from '../../constants/DefaultValue';
import Error from '../special/error';
import { DNA } from 'react-loader-spinner';



export default function ConsultantsListing({ activeIndex, consultSelect,refresh,countryPrefer,consLangId,setConsLangId,activIndexConsultant,consSerId,activIndexConsultantService }) {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);
  console.log("activIndex1111111111111",consLangId);
  const languageFilters = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
};
  const serviceFilters = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
};


  console.log("activIndex1111111111111",activIndexConsultant);
  const fetchAllConsultants = () => {
    axios
      .post(`${REST_API_END_POINT}consultant/get-all-consultant`, { from_user: true })
      .then((res) => {
        if (res.data.status === 1) {
          let filteredData = res.data.data
            .filter((d) => d.status === 1)  
            .filter((d) => 
              d.location.toLowerCase().includes(countryPrefer.name.toLowerCase())
            ); 
  
          console.log("Filtered Data (initial):", filteredData);
          if (activIndexConsultantService in serviceFilters) {
            const languageId = serviceFilters[activIndexConsultantService];
            filteredData = filteredData.filter((d) =>
              d.visa_services?.split(',').includes(languageId)
            );
            console.log(`Filtered by Service ID ${languageId}:`, filteredData);
          }
          if (activIndexConsultant in languageFilters) {
            const languageId = languageFilters[activIndexConsultant];
            filteredData = filteredData.filter((d) =>
              d.languages?.split(',').includes(languageId)
            );
            console.log(`Filtered by Language ID ${languageId}:`, filteredData);
          }
          setData(filteredData);
        } else {
          setData([]);
        }
      })
      .catch((err) => {
        console.error("Error fetching consultants:", err);
      });
  };
  
  
  

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);
    fetchAllConsultants()
    return () => clearTimeout(timer);
  }, [refresh,countryPrefer,activIndexConsultant,activIndexConsultantService])

  useEffect(() => {
    setData([consultSelect])
  }, [consultSelect])

  return (
    <>
    {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh',
          }}
        >
        <DNA
        visible={true}
        height="140"
        width="140"     
        ariaLabel="dna-loading"
        wrapperStyle={{color:"black"}}
         wrapperClass="dna-wrapper"
         
           />
        </div>
      ) : (
      <section className="relative mb-6" style={{ marginTop: '30px' }}>
        {data?.length>0?
        
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[30px]" style={{ padding: '20px' }}>
          
        {
            data?.map((ele, index) => (
              <div
                onClick={() => {
                  ele.detail_added === 1 && ele.status == 1 &&ele.verified==1 &&
                    navigate(`/consultants-details/${ele.id}/${activeIndex}`)
                }}
                style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'stretch', alignContent: 'stretch' }}>
                <div key={index} className="group relative overflow-hidden shadow dark:shadow-gray-700 hover:shadow-md transition-all duration-100 " style={{ borderRadius: '20px', }}>
                  <img style={{ maxWidth: '100%', height:'300px',width:'600px', objectFit: 'cover' }}
                    src={ele.cover_image} alt={ele.name} />

                  <div className="relative p-6">
                    <div className="bagde-flag-wrap1">
                      <img
                        style={{
                          position: 'absolute',
                          top: '0',
                          right: '0',
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          zIndex: '2',
                        }}
                        src={ele.logo}
                        alt="Logo"
                      />
                    </div>

                    {(ele.detail_added != 1||ele.verified != 1)?(<div>
                      <div className="color-price" style={{ marginTop: '-10px', marginLeft: '-10px' }}>
                        {/* <div className="color-option1" >
                          <Link onClick={() => {
                            ele.detail_added === 1 && ele.status == 1 &&
                              navigate(`/languages-details/${ele.id}/${activeIndex}`)
                          }}
                            style={{ fontWeight: 'bold', fontSize: '21px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignContent: 'center',  maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} class="color">{ele.name}</Link>
                        </div> */}
                        <div className="color-price" style={{ marginTop: '10px', marginLeft: '-10px', position: 'relative', display: 'flex', justifyContent: 'flex-start' }}>
                          <div className="color-option">
                            <div className="flex items-center" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Link
                                 data-ripple-light="true"
                                  data-tooltip-target="tooltip"
                                onClick={() => {
                                  ele.detail_added === 1 && ele.status == 1 &&
                                    navigate(`/consultants-details/${ele.id}/${activeIndex}`)
                                }} className="color" style={{ fontWeight: 'bold', fontSize: '21px', color: '#333333', maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: '10px', marginBottom: '6px' }}>{ele.name}
                                </Link>
                                <div
                                  data-tooltip="tooltip"
                                  class="tooltip"
                                >
                                  {ele.name}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* {(ele.detail_added != 1||ele.verified != 1) ? ( */}
                          <div className="price" style={{ marginRight: '-15px' }}>
                          <div className="color-option">
                            <div className="flex items-center justify-center" style={{ marginTop: '0px', textAlign: 'center' }}>
                              <Link style={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignContent: 'center', maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} class="color">
                                Coming Soon
                              </Link>
                              {/* <Link className="color" style={{ marginLeft: '0px', color: '#000000', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer', textAlign: 'center' }}>
                              {ele.rating}
                              </Link> */}
                              {/* <div style={{ marginRight: '-15px' }}>
                                <img src={star} height={32} width={32} alt="Image Description" className="badge-image" style={{ marginLeft: '-5px', marginTop: '3px' }} />
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="color-price" style={{
                        marginTop: '10px',
                        marginLeft: '-10px', position: 'relative', display: 'flex', justifyContent: 'flex-start'
                      }}>
                        <div className="color-option">
                          <div className="flex items-center" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div style={{
                              fontSize: '26px', color: '#000000',
                              marginRight: '18px'
                            }}>
                              <span class="ion--location-outline"></span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <Link 
                              // onClick={() => {
                              //   ele.detail_added === 1 && ele.status == 1 &&
                              //     navigate(`/consultants-details/${ele.id}/${activeIndex}`)
                              // }}
                              data-ripple-light="true"
                                  data-tooltip-target="tooltip"
                                className="color" style={{ fontSize: '15px', color: '#000', maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                {ele.location}
                                <span className="underline"></span>
                              </Link>
                              <div
                                  data-tooltip="tooltip"
                                  class="tooltip"
                                >
                                  {ele.location}
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* {ele.verified !=1 ? (
                                             <div style={{ display: 'flex', flexDirection: 'column',marginTop:'20px' }}>
                                             <Link 
                                             className="color"  style={{ fontSize: '18px', color: '#858585',
                                              marginBottom: '4px',fontWeight:'bold' }}>Coming Soon</Link>                                            
                                           </div>
                                           ):(
                                            <div className="color-price" 
                                            style={{ marginTop: '30px', marginLeft: '-10px' }}>
                                            <div className="flex items-center">
                                            <div style={{ fontSize: '35px', 
                                              color: '#000000', marginRight: '10px' }}>
                                              <span style={{marginLeft:'-3px'}} 
                                              class="mdi--dollar"></span>
                                              </div>
                                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Link onClick={()=>{
                                               ele.detail_added===1 && ele.status==1 && 
                                                navigate(`/consultants-details/${ele.id}/${activeIndex}`)
                                               }}
                                                className="color"  style={{ fontSize: '18px', color: '#858585',
                                                 marginBottom: '4px',fontWeight:'bold' }}>Consultants Fee</Link>
                                                 {ele.detail_added==1 && 
                                                <Link onClick={()=>{
                                               ele.detail_added===1 && ele.status==1 && 
                                                navigate(`/consultants-details/${ele.id}/${activeIndex}`)
                                               }} 
                                                className="color"  style={{ fontSize: '16px', color: '#000000',
                                                fontWeight:'bold' }}>$ {ele.consultant_fee}
                                                </Link>    
                                                 }  
                                              </div>
                                            </div>
                                          </div>
                                          )} */}


                      <div className="color-price" style={{ marginTop: '30px', marginLeft: '-12px' }}>
                        <div className="flex items-center">
                          <div style={{ fontSize: '30px', color: '#000000', marginRight: '16px' }}>
                          <span   class="mdi--dollar"></span>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link 
                            // onClick={() => {
                            //   ele.detail_added === 1 && ele.status == 1 && ele.verified==1 &&
                            //     navigate(`/consultants-details/${ele.id}/${activeIndex}`)
                            // }}
                              className="color" style={{ fontSize: '18px', color: '#858585', marginBottom: '4px', fontWeight: 'bold' }}>Consultants Fee</Link>

 
                            <Link 
                            // onClick={() => {
                            //   ele.detail_added === 1 && ele.status == 1 &&  ele.verified === 1 &&
                            //     navigate(`/consultants-details/${ele.id}/${activeIndex}`)
                            // }}
                              className="color" style={{ fontSize: '16px', color: '#000000', fontWeight: 'bold' }}>{ele.consultant_fee ? ele.consultant_fee : 'Not Yet Added'}</Link>

                          </div>
                        </div>
                      </div>


                    </div>)
                    :(<div>
                      <div className="color-price" style={{ marginTop: '-10px', marginLeft: '-10px' }}>
                        {/* <div className="color-option1" >
                          <Link onClick={() => {
                            ele.detail_added === 1 && ele.status == 1 &&
                              navigate(`/languages-details/${ele.id}/${activeIndex}`)
                          }}
                            style={{ fontWeight: 'bold', fontSize: '21px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignContent: 'center',  maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} class="color">{ele.name}</Link>
                        </div> */}
                        <div className="color-price" style={{ marginTop: '10px', marginLeft: '-10px', position: 'relative', display: 'flex', justifyContent: 'flex-start' }}>
                          <div className="color-option">
                            <div className="flex items-center" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Link
                                 data-ripple-light="true"
                                  data-tooltip-target="tooltip"
                                onClick={() => {
                                  ele.detail_added === 1 && ele.status == 1 &&
                                    navigate(`/consultants-details/${ele.id}/${activeIndex}`)
                                }} className="color" style={{ fontWeight: 'bold', fontSize: '21px', color: '#333333', maxWidth: 250, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: '10px', marginBottom: '6px' }}>{ele.name}
                                </Link>
                                <div
                                  data-tooltip="tooltip"
                                  class="tooltip"
                                >
                                  {ele.name}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                       
                      </div>

                      <div className="color-price" style={{
                        marginTop: '10px',
                        marginLeft: '-10px', position: 'relative', display: 'flex', justifyContent: 'flex-start'
                      }}>
                        <div className="color-option">
                          <div className="flex items-center" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div style={{
                              fontSize: '26px', color: '#000000',
                              marginRight: '18px'
                            }}>
                              <span class="ion--location-outline"></span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <Link onClick={() => {
                                ele.detail_added === 1 && ele.status == 1 &&
                                  navigate(`/consultants-details/${ele.id}/${activeIndex}`)
                              }}
                              data-ripple-light="true"
                                  data-tooltip-target="tooltip"
                                className="color" style={{ fontSize: '15px', color: '#000', maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                {ele.location}
                                <span className="underline"></span>
                              </Link>
                              <div
                                  data-tooltip="tooltip"
                                  class="tooltip"
                                >
                                  {ele.location}
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* {ele.verified !=1 ? (
                                             <div style={{ display: 'flex', flexDirection: 'column',marginTop:'20px' }}>
                                             <Link 
                                             className="color"  style={{ fontSize: '18px', color: '#858585',
                                              marginBottom: '4px',fontWeight:'bold' }}>Coming Soon</Link>                                            
                                           </div>
                                           ):(
                                            <div className="color-price" 
                                            style={{ marginTop: '30px', marginLeft: '-10px' }}>
                                            <div className="flex items-center">
                                            <div style={{ fontSize: '35px', 
                                              color: '#000000', marginRight: '10px' }}>
                                              <span style={{marginLeft:'-3px'}} 
                                              class="mdi--dollar"></span>
                                              </div>
                                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Link onClick={()=>{
                                               ele.detail_added===1 && ele.status==1 && 
                                                navigate(`/consultants-details/${ele.id}/${activeIndex}`)
                                               }}
                                                className="color"  style={{ fontSize: '18px', color: '#858585',
                                                 marginBottom: '4px',fontWeight:'bold' }}>Consultants Fee</Link>
                                                 {ele.detail_added==1 && 
                                                <Link onClick={()=>{
                                               ele.detail_added===1 && ele.status==1 && 
                                                navigate(`/consultants-details/${ele.id}/${activeIndex}`)
                                               }} 
                                                className="color"  style={{ fontSize: '16px', color: '#000000',
                                                fontWeight:'bold' }}>$ {ele.consultant_fee}
                                                </Link>    
                                                 }  
                                              </div>
                                            </div>
                                          </div>
                                          )} */}


                      <div className="color-price" style={{ marginTop: '30px', marginLeft: '-12px' }}>
                        <div className="flex items-center">
                          <div style={{ fontSize: '30px', color: '#000000', marginRight: '16px' }}>
                          <span   class="mdi--dollar"></span>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link onClick={() => {
                              ele.detail_added === 1 && ele.status == 1 &&
                                navigate(`/consultants-details/${ele.id}/${activeIndex}`)
                            }}
                              className="color" style={{ fontSize: '18px', color: '#858585', marginBottom: '4px', fontWeight: 'bold' }}>Consultants Fee</Link>

 
                            <Link onClick={() => {
                              ele.detail_added === 1 && ele.status == 1 &&  ele.verified == 1 &&
                                navigate(`/consultants-details/${ele.id}/${activeIndex}`)
                            }}
                              className="color" style={{ fontSize: '16px', color: '#000000', fontWeight: 'bold' }}>{ele.consultant_fee ? ele.consultant_fee : 'Not Yet Added'}</Link>

                          </div>
                        </div>
                      </div>


                    </div>)}

                  </div>
                </div>
              </div>
           ))
          }
        </div>
         
        
          : <Error />}
        {/* <div className="container mt-8" style={{display:'flex',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                        <div className="flex items-center">
                          <Link style={{color: '#000000', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer',marginTop:'15px',}}>
                         Load More
                          </Link>
                          <div style={{marginTop:'17px',fontSize:'40px',color:'#000000',cursor:'pointer' }} >
                          <RiArrowDropDownLine  style={{color:'#000000'}}/>
                          </div>
                        </div> 
                    </div>   */}
      </section>
    )}
    </>
  )
}
